.email-container {
  text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  background-color: #006D7E;
}

.email-table {
  width: 100%;
  border: none;
  border-spacing: 0;
}

.email-td {
  padding: 0;
}

.inner-table {
  width: 94%;
  max-width: 600px;
  border: none;
  border-spacing: 0;
  text-align: left;
  font-family: Arial, sans-serif;
  font-size: 16px;
  line-height: 22px;
  color: #363636;
}

.logo-td {
  padding: 40px 30px 30px 30px;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
}

.logo-link {
  text-decoration: none;
}

.logo-img {
  width: 165px;
  max-width: 80%;
  height: auto;
  border: none;
  text-decoration: none;
  color: #ffffff;
}

.content-td {
  padding: 30px;
  background-color: #ffffff;
  h1{
    text-align: center;
  }
  p{
    text-align: center;
  }
}

.content-link {
  text-decoration: none;
}

.content-img {
  width: 100%;
  height: auto;
  display: block;
  border: none;
  text-decoration: none;
  color: #363636;
}

.button-container {
  display: flex;
  justify-content: center;
}

.close-button {
  margin: 0;
  align-self: center;
  border-radius: 12px;
  background-color: #05728d;
  border: none;
  color: white;
  padding: 16px;
  text-align: center;
  font-size: 12px;
}

.footer-td {
  padding: 30px;
  text-align: center;
  font-size: 12px;
  background-color: #404040;
  color: #cccccc;
}

.social-icon {
  display: inline-block;
  color: #cccccc;
}

.unsub-link {
  color: #cccccc;
  text-decoration: underline;
}
