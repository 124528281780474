.centered-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 90px auto 20px; /* Centering the button with auto margin */
    text-align: center;
  }
  .handshake{
    text-align: center;
    margin-top: -1rem;
    height: 327px;
    margin-left: -383px;
    width: auto;
  }
  .partner-container {
    text-align: center;
    padding: 20px;
    flex: 1;
    border-radius: 10px; /* Rounded corners for a more modern look */
    
  }
  .sliding-bg-container {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  
  /* Background image slider */
  .sliding-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 1400%; /* Ensures smooth looping */
    height: 100%;
    display: flex;
    animation: slide 70s linear infinite;
  }
  
  .sliding-bg img {
    width: 12.5%; /* Each image takes up exactly 12.5% of the full width */
    height: 100%;
    object-fit: cover;
  }
  
  /* Smooth continuous sliding animation */
  @keyframes slide {
    0% { transform: translateX(0); }
    100% { transform: translateX(-50%); } /* Moves exactly half for seamless looping */
  }
  
  /* Light black overlay for better text visibility */
  .sliding-bg-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.75); /* Increased opacity for better readability */
    z-index: 1; /* Ensures it stays above the background images */
  }
  .heading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 20px;
  }
  
  .hip-pro-plus-container2 {
    margin-bottom: 20px; /* Space between heading and image */
  }
  
  .hip-pro-plus-heading3 {
    font-size: 34px;
    font-weight: bold;
    color: #fff;
    animation: fadeInOut 3s ease-in-out infinite;
    max-width: 90%;
  }
  
  .image-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  
  .heading-image {
    width: 400px; /* Uniform size */
    height: 250px;
    object-fit: cover; /* Ensures images are consistent */
    border-radius: 12px;
    transition: opacity 1s ease-in-out;
  }
  
  
  @keyframes fadeInOut {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  .partner-heading {
    font-size: 32px; /* Adjusted for better readability */
    font-weight: 700;
    color: #ffffff; /* White color for the heading */
    margin-bottom: 20px;
    font-family: 'Roboto', sans-serif; /* A professional font */
    letter-spacing: 1px; /* Slight letter spacing for a cleaner look */
  }
  
  .partner-description {
    font-size: 18px;
    color: #f4f4f4; /* Lighter gray for better readability against dark background */
    margin-bottom: 25px;
    font-family: 'Roboto', sans-serif; /* Consistent font with heading */
  }
  
  .partner-list {
    list-style-type: none;
    padding: 0;
    margin: 0 auto;
    display: inline-block;
    text-align: left;
    max-width: 500px; /* Limit width for better presentation */
  }
  
  .partner-item {
    font-size: 18px;
    color: #dcdcdc; /* Lighter gray for list items */
    margin-bottom: 15px;
    position: relative;
    padding-left: 25px;
    font-family: 'Roboto', sans-serif; /* Consistent font */
  }
  
  .partner-item::before {
    content: "•";
    color: #0f8894; /* Accent color for bullet */
    font-size: 20px;
    position: absolute;
    left: 0;
    top: 0;
  }
  .part-image {
    text-align: center;
    margin-top: -7rem;
    margin-bottom: -5rem;
    height: 400px;
    margin-left: -383px;
    width: auto;
  }
  .responsive-rectangle {
    width: 100%;
    padding: 20px;
    background-color: #ededef;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    margin: 20px 0;
  }
  
  /* Heading */
  .responsive-heading {
    font-size: 2rem;
    color: #333;
    margin-bottom: 10px;
  }
  
  /* Description */
  .responsive-description {
    font-size: 1rem;
    color: #666;
    margin-bottom: 20px;
  }
  
  /* Box Container */
  .responsive-box-container {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap; /* Allows items to stack on small screens */
  }
  
  /* Individual Boxes */
  .responsive-box {
    width: 30%;
    min-width: 250px; /* Ensures a minimum size */
    height: auto;
    background-color: rgb(1, 140, 146);
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .responsive-box:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  /* Images */
  .responsive-box-image {
    width: 80%;
    max-height: 200px;
    object-fit: contain;
    margin-bottom: 10px;
    border-radius: 5px;
  }
  
  /* Points List */
  .responsive-box-points {
    list-style-type: none;
    padding: 0;
    margin: 0;
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
  }
  
  .responsive-box-points li {
    margin-bottom: 8px;
    padding: 8px 15px;
    background-color: #018c92;
    color: #ffffff;
    border-radius: 6px;
    font-size: 1rem;
    transition: background-color 0.3s ease;
  }
  
  .responsive-box-points li:hover {
    background-color: #016a6f;
  }
  
  /* Button Container */
  .button-container {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }
  
  /* Button */
  .ms-secondary-btn {
    padding: 10px 20px;
    background-color: #018c92;
    color: white;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  
  .ms-secondary-btn:hover {
    background-color: #016a6f;
  }
  
  /* Responsive Design */
  @media screen and (max-width: 1024px) {
    .responsive-box {
      width: 45%; /* Make boxes wider on tablets */
    }
  }
  
  @media screen and (max-width: 768px) {
    .responsive-box-container {
      flex-direction: column; /* Stack items on small screens */
      align-items: center;
    }
  
    .responsive-box {
      width: 80%; /* Increase width for readability */
    }
  }
  
  @media screen and (max-width: 480px) {
    .responsive-heading {
      font-size: 1.8rem;
    }
  
    .responsive-description {
      font-size: 0.9rem;
    }
  
    .responsive-box {
      width: 90%; /* Full width on very small screens */
    }
  
    .responsive-box-points li {
      font-size: 0.9rem;
    }
  }

 /* Base styles */
.custom-section {
  width: 100%;
  background-color: #f4f5f7;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 20px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.custom-container {
  width: 100%;
  max-width: 1200px;
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  padding: 30px;
  text-align: center;
}

/* Heading styles */
.responsive-heading h1 {
  font-size: 2rem;
  color: #2c3e50;
  margin-bottom: 10px;
}

.responsive-description {
  font-size: 1.1rem;
  color: #555;
  margin-bottom: 25px;
  line-height: 1.6;
}

/* Flexbox container */
.custom-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
}

/* Image styles */
.custom-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 50%;
}

.custom-image img {
  width: 100%;
  max-width: 400px;
  height: auto;
  border-radius: 12px;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
  transition: transform 0.3s ease-in-out;
  background-color: #016a6f;
}

.custom-image img:hover {
  transform: scale(1.05);
}

/* Points section */
.custom-points {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 15px;
  text-align: left;
}


.points-row {
  display: contents; /* Allows span elements to align properly */
}

.points-row span {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  color: #fff;
  padding: 15px; /* Increased padding for better readability */
  background: #018c92;
  border-radius: 8px;
  font-weight: 500;
  text-align: center;
  transition: all 0.3s ease;
  min-height: 60px; /* Ensures equal height */
}

.points-row span:hover {
  background: #01666a;
}

/* Button */
.custom-button {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}



.next-btn:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

/* Responsive Design */
@media (max-width: 1024px) {
  .custom-content {
    flex-direction: column;
    align-items: center;
  }

  .custom-image {
    max-width: 80%;
  }

  .points-row {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .points-row span {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .responsive-heading h1 {
    font-size: 1.8rem;
  }

  .responsive-description {
    font-size: 1rem;
  }

  .custom-image {
    max-width: 100%;
  }

  .next-btn {
    font-size: 1.1rem;
    padding: 10px 20px;
  }
}

@media (max-width: 480px) {
  .custom-container {
    padding: 20px;
  }

  .responsive-heading h1 {
    font-size: 1.5rem;
  }

  .responsive-description {
    font-size: 0.9rem;
  }

  .points-row span {
    font-size: 0.9rem;
    padding: 10px;
  }

  .next-btn {
    font-size: 1rem;
    padding: 8px 18px;
  }
  .part-image {
    height: 244px;
    width: 68%;
    margin-left: 0;
    margin-bottom: 20px;
  }
}
.partnership-section {
  width: 100%;
  min-height: 80vh;
  background-color: #f4f5f7;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  margin-bottom: 100px;
}

.partnership-container {
  width: 90%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: #f4f5f7;
  border-radius: 15px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  padding: 30px;
}

.partnership-header h1 {
  font-size: 2.4rem;
  color: #fff;
  margin-bottom: 15px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1.2px;
}

.partnership-header p {
  font-size: 1.2rem;
  color: #fff;
  margin-bottom: 25px;
  line-height: 1.8;
}

.partnership-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
  margin-top: 20px;
}

.partner-eligibility,
.differentiators {
  flex: 1;
  background-color: #018c92;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
}

.partner-eligibility h2,
.differentiators h2 {
  font-size: 1.8rem;
  color: #fff;
  margin-bottom: 15px;
  font-weight: 600;
  text-align: left;
}

.eligibility-points,
.differentiators-points {
  list-style: none;
  padding: 0;
}

.eligibility-points li,
.differentiators-points li {
  font-size: 1.1rem;
  color: #fff;
  padding: 10px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.eligibility-points li:last-child,
.differentiators-points li:last-child {
  border-bottom: none;
}

/* Responsive Design */

/* Tablet screens */
@media (max-width: 1024px) {
  .partnership-container {
      padding: 20px;
  }

  .partnership-header h1 {
      font-size: 2rem;
  }

  .partnership-header p {
      font-size: 1rem;
  }

  .partner-eligibility h2,
  .differentiators h2 {
      font-size: 1.6rem;
  }

  .eligibility-points li,
  .differentiators-points li {
      font-size: 1rem;
  }
}

/* Mobile screens */
@media (max-width: 780px) {
  .partnership-content {
      flex-direction: column;
      gap: 15px;
      align-items: center; /* Centers the child elements */
      text-align: center; /* Centers text content */
  }

  .partner-eligibility,
  .differentiators {
      width: 90%; /* Ensures they don't stretch too wide */
      max-width: 500px; /* Prevents too much expansion on larger mobile screens */
      padding: 15px;
  }

  .partnership-header h1 {
      font-size: 1.8rem;
  }

  .partnership-header p {
      font-size: 0.95rem;
  }

  .partner-eligibility h2,
  .differentiators h2 {
      font-size: 1.4rem;
  }

  .eligibility-points li,
  .differentiators-points li {
      font-size: 0.95rem;
  }
}


/* Small mobile screens */
@media (max-width: 480px) {
  .partnership-container {
      width: 95%;
      padding: 15px;
  }

  .partnership-header h1 {
      font-size: 1.5rem;
  }

  .partnership-header p {
      font-size: 0.9rem;
  }

  .partner-eligibility h2,
  .differentiators h2 {
      font-size: 1.2rem;
  }

  .eligibility-points li,
  .differentiators-points li {
      font-size: 0.9rem;
      padding: 8px 0;
  }
  .part-image {
    height: 246px;
    width: 54%;
    margin-left: 0;
    margin-bottom: 50px;
  }
}

  
  @media (max-width: 1320px) {
    .part-image {
       
        margin-left: -243px;
       
      }
  }
  @media (max-width: 1120px) {
    .part-image {
       
        margin-left: -143px;
       
      }
  }
  /* Tablet styles */
  @media (max-width: 1024px) {
    .part-image {
      height: 270px;
      margin-left: -105px;
      margin-top: 2px;
  }
  }
  @media (max-width: 504px) {
    .part-image {
      height: 199px;
        margin-left: -5px;
        margin-top: -38px;
  }
  .hip-pro-plus-heading {
    font-size: 20px;
    width: 101%;
    margin-left: 0;
    text-align: center;
}
.hip-pro-plus-heading2 {
  font-size: 10px;
  width: 80%;
  margin-left: 0;
  text-align: center;
  margin-top: -86px;
}
  }
  @media (max-width: 900px) { 
    .hip-pro-plus-heading3 {
      font-size: 24px;
      font-weight: bold;
      text-align: center;
      color: #fff;
      animation: fadeInOut 3s ease-in-out infinite;
      margin-top: -70px;
    }
    .hip-pro-plus-container2 {
      margin-left: 0;
      margin-top: -30px;
    }
  }
  @media (max-width: 768px) { /* Small Mobile */
    
    
    .sliding-bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 1600%; /* Ensures smooth looping */
      height: 80%;
      display: flex;
      animation: slide 20s linear infinite;
    }
    .hip-pro-plus-heading3 {
      font-size: 24px;
      font-weight: bold;
      text-align: center;
      color: #fff;
      animation: fadeInOut 3s ease-in-out infinite;
      margin-top: -70px;
    }
    .hip-pro-plus-container2 {
      margin-left: 0;
      margin-top: -30px;
    }
    .image-container {
      display: none; /* Hides the image on mobile */
    }
  }
  @media (max-width: 640px) { /* Small Mobile */
    
    
    .sliding-bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 1600%; /* Ensures smooth looping */
      height: 80%;
      display: flex;
      animation: slide 20s linear infinite;
    }
    .hip-pro-plus-heading3 {
      font-size: 16px;
      font-weight: bold;
      text-align: center;
      color: #fff;
      animation: fadeInOut 3s ease-in-out infinite;
      margin-top: -70px;
      margin-left: 10px;
      width: 100%;
    }
    .hip-pro-plus-container2 {
      margin-left: 0;
      margin-top: -30px;
    }
    .image-container {
      display: none; /* Hides the image on mobile */
    }
  }
 