/* Doctorprofile.css */

.container {
  max-width: 932px;
  margin: 0 auto;
  padding: 20px;
}

.card {
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Adjust the values as needed */

}

.card h1 {
  margin-top: 0;
}
.schedule-container {
  display: flex;
  flex-wrap: wrap; /* Wrap the cards to the next line if there's not enough space */
}

.langua.card {
  flex: 0 0 calc(50% - 10px); /* Each card takes 50% of the container width with some margin */
  margin-right: 10px; /* Add margin between cards */
  margin-bottom: 10px; /* Add margin between rows */
}

.languages,
.experience,
.about,
.faqs,
.qualifications,
.registrations,
.specializations,
.services {
  margin-top: 20px;
}

.question {
  display: flex;
  align-items: center;
}

.question button {
  margin-left: auto;
}

.answer {
  margin-left: 20px;
}

@media screen and (max-width: 600px) {
  .container {
    padding: 30px;
    padding-right: 30px;
    padding-left: 30px;
  }

  .card {
    padding: 10px;
  }

  .question button {
    font-size: 0.8rem;
  }
}
.discount {
  flex-shrink: 0;
  width: 2500px; /* Adjust width as needed */
  max-width: 2000%; /* Adjust max-width as needed */
  border: 1px solid #ffa000;
  padding: 16px 21px;
  margin: auto;
  box-sizing: border-box;
  margin-left: -600px; /* Adjust initial position as needed */
  z-index: -1;
  background-color: #ffa000;
  overflow: hidden; /* Ensures the animation is confined to the container */
  position: relative; /* Required for absolute positioning of child element */
}

.discount h5 {
font-size: 20px;
  color: #fff;
  text-align: center;
  margin-top: -12px;
  position: absolute; /* Allows animation */
  left: 100%; /* Initial position outside the container */
  animation: moveDiscount 25s linear infinite; /* Adjust animation duration and timing as needed */
}

@keyframes moveDiscount {
  0% {
    left: 100%; /* Start position */
  }
  100% {
    left: -100%; /* End position */
  }
}
.sociallinks a {
  background: #fff;
  width: 40px;
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: var(--ms-primary-color);
  margin: 0 3px;
  border: 1px solid #fff;
}
.sociallinks a:hover {
  background: var(--ms-primary-color);
  color: #fff;
}

.dta{
  margin-top: 83px;
}
.docimage{
  display: flex;
  /* margin-bottom: 1rem; */
  /* margin-top: 6rem; */

}
@media (min-width: 768px) {
  .margin-bottom-medium {
    margin-bottom: 1.5rem;
  }
}
.docimage img {
  height: 140px;
  width: 140px;
  border-radius: 50%;
  margin-left: -14px;
  /* margin-top: -50px; */
  
}
