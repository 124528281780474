.ms-book-banner {
   background:  #018C92;
  background-repeat: no-repeat;
  background-position:  center;
  background-size: cover;
  padding:101px 10px 106px;
}
/*----- 05.b. Appointment Section -----*/
.ms-appointment-section {
  background: #f6f7f8;
  padding: 2px 10px;
}
.ms-appointment-section select {
  border-radius: 30px;
  padding:5px 10px;
  font-size: 14px;
  color: var(--ms-third-color);
  border: 1px solid #e1e1e1;
}
.ms-doctors .ms-doc-img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-right: 20px;
}
.ms-doctors p {
  font-size: 15px;
  color:var(--ms-third-color);
}
.ms-doctors {
  border: 1px solid #e1e1e1;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0px 2px 5px 0 rgb(188 188 188 / 20%);
}
.ms-doctors .ms-left-border {
  border-left: 1px solid #e1e1e1;
  padding-left: 20px;
}
.ms-doctors .ms-btn {
  font-size: 15px;
  padding: 8px 20px;
}
.ms-doctors .ms-icon {
  min-width: 15px;
}
.ms-sort-txt {
  font-size: 14px;
}

.booking__discount{
  border: 1px solid red;
  /* width: max-content; */
  padding: 6px 20px;
  margin:  auto !important;
  border-radius: 50px
}

/*........... appointment tab ....................  */

.appointment__screen{
 margin: 60px 0px;
 width: 100%;
}
@media (min-width:310px) {
  .ms-book-banner {   
      padding: 70px 0px 39px;
  }
}

@media (min-width:991px) {
  .ms-doctors .ms-left-border {
      border-left: 1px solid #e1e1e1;
      padding-left: 30px;
  }
}
@media (max-width:991px) {
  .ms-no-border {
      border:none !important;
  }
  .ms-doctors .ms-left-border {
      border-top:1px solid #e1e1e1 !important;
      padding:20px;
  }
}
@media (max-width:767px) {
  .ms-doctors .ms-left-border {
      border: none;
      padding-left: 10px;
      padding-top: 15px;
  }
  .ms-doctors .ms-doc-img {
      width: 80px;
      height: 80px;
      margin-right: 10px;
  }
  .ms-doctors .ms-btn {
      width: 100%;
      text-align: center;
      padding: 7px 15px;
  }
  .ms-doctors p {
      font-size: 14px;
  }
}
.custom-modal {
  width: 80%; /* Set your desired width */
  max-width: none; /* Remove max-width constraint */
  height: 80%; /* Set your desired height */
}
/* Main Container */
