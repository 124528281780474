

.login_modal{ 
 display: flex;
 justify-content: center;
 align-items: center;
 /* border: 1px solid red; */
 width: 100% !important;
 height: 100% !important;
}

.login_modal_content{
    position: relative;
    min-width: 300px;
    width:550px;
    margin: auto !important;
    height: 400px;
    border-radius: 20px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
    justify-content: center;
    gap: 40px;
    flex-direction: column;
    padding: 25px;
}

.login__Cancel{
    color: var(--ms-primary-color);
    position: absolute;
    top: 20px ;
    right: 20px;
    cursor: pointer;
}

.iframe_btn{
    position: absolute;
    top: 4px;
    right:25px;
    cursor: pointer;
}

.iframe__container{
    border-radius: 20px;
}

.iframe__container__patient{
    background: white;
    background-repeat: no-repeat;
    background-position:  center;
    background-size: cover;
}

.iframe__container__doctor{
    background: white;
    background-repeat: no-repeat;
    background-position:  center;
    background-size: cover;
}


.login__heading{
    text-align: center;
    font-size: 26px;
    font-weight: 550;
}

.login__btns{
    /* border: 1px solid red; */
    gap: 20px;
}

.login__btns>button{
   width: 80%;
   padding: 8px 0px;
   border-radius: 50px;
}
.login__btns>button>img{
  width: 35px;
  color:white !important; 
 }
 

.login__btn{
    margin: 0px 80px 0px 10px;
    /* border: 1px solid red; */
    font-size: 20px;
}


@media (max-width:767px){

.iframe_btn{
    right: 0px;
}    
  
.login_modal_content{
    width:400px;
    min-width: 300px;
    height: 400px;
}


.login__heading{
    font-size: 20px;
}

.login__btn{
    /* border: 1px solid red; */
    font-size: 16px;
}


}


/************** HOSPITAL PORTAL PAGE **************/
.portal_modal_content{
    gap: 10px !important;
    height: 500px !important;
}

.portal_search{
    margin: 0px auto;
    width: 70%;
    
}

.portal_search>input{
    border-radius:10px !important ;
    padding: 10px !important;
    
}

.portal_search>p{
    font-size: 14px ;
    text-align: center;
}

.portal_btns{
    gap: 20px;
    overflow-y:scroll;
    
}
.portal_btns>button{
    width: 80% !important;
    border-radius: 10px;
    padding: 10px 0px !important;
}

.portal_btn_first>img{
    max-width: 60%;
}

.portal_btn_second>h5{
    font-size: 14px !important;
    margin-bottom: 0px;
}

.portal_btn_second>p{
    margin-bottom: 0px;
    margin-top: 6px;
    font-size: 14px !important;
}