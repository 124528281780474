.honor-section-container {
  background-color: #ededef;
  padding: 40px 20px;
  text-align: center;
  border-radius: 10px;
  margin: 20px auto;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.honor-heading {
  font-size: 2.5rem;
  color: #141414;
  margin-bottom: 10px;
}

.honor-description {
  font-size: 16px;
  color: rgb(133, 133, 133);
  margin-bottom: 30px;
  line-height: 1.6;
  padding: 0 15px;
}

.marquee-container {
  overflow: hidden;
  width: 100%;
  background-color: #f9f9f9;
  padding: 10px 0;
}

.marquee {
  display: flex;
  animation: scroll 30s linear infinite;
  align-items: center;
  will-change: transform;
  gap: 30px;
  min-width: calc(300px * 10 + 30px * 9); /* Adjust this based on card width and gap */
}

@keyframes scroll {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.honor-card-custom {
  width: 300px;
  position: relative;
  text-align: center;
  border: 1px solid #018c92;
  border-radius: 10px;
  overflow: hidden;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  background-color: #018c92;
  flex-shrink: 0;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.honor-card-custom:hover {
  transform: scale(1.05);
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
}

.honor-image-container {
  position: relative;
  width: 100%;
  height: 200px;
  overflow: hidden;
}

.honor-doctor-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.honor-achievement-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 92, 116, 0.9);
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: opacity 0.3s ease-in-out;
  padding: 10px;
  text-align: left;
}

.honor-card-custom:hover .honor-achievement-overlay {
  opacity: 1;
}

.honor-achievement-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  color: #fff;
  font-size: 14px;
  line-height: 1.5;
}

.honor-achievement-item {
  margin-bottom: 5px;
}

.honor-doctor-name {
  font-size: 18px;
  font-weight: bold;
  margin: 10px 0;
  color: #fff;
  text-transform: uppercase;
}

.honor-doctor-specialization {
  font-size: 16px;
  font-weight: 500;
  margin: 5px 0;
  color: #ffff; /* Golden color for specialization */
  text-transform: capitalize;
}

.honor-doctor-hospital {
  font-size: 14px;
  font-weight: 400;
  color: #fff; /* Light gray for hospital name */
  margin: 5px 0;
  font-style: italic;
}


/* Responsive Design */
/* Mobile-specific styles for consistent box size */
@media (max-width: 768px) {
  .honor-card-custom {
    width: 250px;
    height: 350px; /* Add fixed height to maintain uniform size */
  }

  .honor-image-container {
    height: 150px; /* Fixed height for the image container */
  }

  .honor-doctor-name,
  .honor-doctor-specialization,
  .honor-doctor-hospital {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; /* Prevent long text from affecting box size */
  }

  .honor-achievement-list {
    font-size: 12px;
    max-height: 70px; /* Limit height of the achievements list */
    overflow: hidden; /* Hide extra content */
    text-overflow: ellipsis;
  }
}

@media (max-width: 480px) {
  .honor-card-custom {
    width: 260px;
    height: 320px; /* Adjust height for smaller devices */
  }

  .honor-image-container {
    height: 200px;
  }

  .honor-doctor-name {
    font-size: 14px;
  }

  .honor-achievement-list {
    max-height: 60px; /* Adjust height for smaller viewports */
  }
  .honor-heading {
    font-size: 1.5rem;
    color: #141414;
    margin-bottom: 10px;
  }
}
