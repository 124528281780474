@import url('https://fonts.googleapis.com/css2?family=Sen&display=swap');

/* ===== Toggle menu ===== */

.res__display{
  display: none;
}

.menu__toggle__content{
    margin-top:40px;
}

.menu_toggle{
    align-self: start;
}

.navbar>a{
    align-self:start;
}

.menu__toggle__content>ul>li{
    list-style: none;
    width: 100%;
    padding: 10px 20px;
    cursor: pointer;
}

.menu__toggle__content>ul>li:hover{
   color: var(--ms-primary-color);
}


.header{
    position: fixed !important;
    /* transition: all 0.1s ease-in-out; */
    z-index: 15;
}

.header__color{
    background-color: white;
}

.ms-main-header {
    position: absolute;
    width: 100%;
}
.ms-main-header .navbar-brand img {
    width: 200px;
    transition: 0.3s all linear;
}

.navbar-brand >img{
    width: 60px !important;
    margin-top: 6px;
}

.ms-main-header nav ul li a {
    color: #333;
    font-weight: 500;
    font-size: 17px;
    margin: 0 18px;
    transition: 0.3s all linear;
}
.ms-main-header nav ul li a.active, 
.ms-main-header nav ul li a:hover {
    color: var(--ms-primary-color);
}
.ms-main-header .ms-header-btn {
    padding: 12px 40px;
}
.ms-main-header .ms-line-slide a {
    position: relative;
}
.ms-main-header .ms-line-slide a:before,
.ms-main-header .ms-line-slide a:after {
    content: '';
    display: block;
    position: absolute;
    bottom:-4px;
    height: 2px;
    width: 0;
    transition: width 0s ease;
}
.ms-main-header .ms-line-slide a:before {
    left: 0;
}
.ms-main-header .ms-line-slide a:after {
    right: 0;
    background: var(--ms-primary-color);
    transition: width .5s ease;
}
.ms-main-header .ms-line-slide a:hover:before {
    width: 100%;
    background: var(--ms-primary-color);
    transition: width .5s ease;
}
.ms-main-header .ms-line-slide a:hover:after {
    width: 100%;
    background: transparent;
}

/* /========== Popup ===============/  */
.login__container{
    height: 100vh;
    width: 100vw ;  
    background-color:rgba(255, 255, 255, 0.598);
    transition: all 0.2s ease-in;

}

@media (min-width:767px){

    .navbar-brand>img{
        width: 140px !important;
    }
    .navbar-brand > img {
      width: 125px !important;
      margin-bottom: 4px;
    }
}

@media (min-width:982px){


    .menu_toggle{
        display: none !important;
    }

    .menu__toggle__content{
        display: none;
    }


}
.dropdown {
    position: absolute;
    top: 100%; /* Adjust as needed */
    left: 0;
    z-index: 1000;
    background: #f9f9f9;
    border: 1px solid #ccc;
    padding: 5px; /* Adjust padding */
    width: 150px; /* Adjust the width */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }
  
  .dropdown > div {
    padding: 5px 0;
    font-size: 15px;
    font-weight: 500;
  }
  
  .dropdown > div:not(:last-child) {
    border-bottom: 1px solid #ccc;
  }
  
  .dropdown span {
    display: block;
    color: #333;
    cursor: pointer;
    transition: color 0.3s;
    text-decoration: none; /* Remove underline */
  }
  
  .dropdown span:hover {
    color: #005c74;
  }
  
  .dropdown-menu-v2 {
    position: relative;
  }
  
  .dropdown-menu-v2 .dropdown {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: 20px;
    left: 0;
  }
  
  .dropdown-menu-v2:hover .dropdown {
    visibility: visible;
    opacity: 1;
  }
  .services-dropdown {
    position: relative;
  }
  
  .services-btn {
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 10px;
    display: none;
  }
  
  .services-dropdown:hover .dropdown-menu,
  .dropdown-menu:hover {
    display: block;
  }
  
  .dropdown-menu li {
    margin-bottom: 5px;
  }
  .dropdown-list {
    animation: fadeIn 0.2s ease-in-out;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .dropdown-item {
    transition: background-color 0.2s ease;
  }
  
  .dropdown-item:hover {
    background-color: #f5f5f5;
  }