/* *-------- Slider Section  -----------------*  */

.home__up__navigation{
    position: fixed    ;
    bottom: 10px;
    width: 100%;
    z-index: 10;
}

.home_up_arrow{
    bottom: inherit;
    width: 45px !important;
    height: 45px;
    border-radius: 50%;
    /* background-color: var(--background); */
    background-color: var(--ms-primary-color);
    cursor:pointer
    
}

.home__swiper{
    width:100vw !important;
    overflow: hidden;
}

.home__swiper__box{
    display: flex !important;
    align-self: end !important;
}

.home__swiper__container>img{
    width: 70% !important;
    height:70% !important;
}


.ms-home-banner {
    overflow: hidden;
    display: flex;
    align-items: center;
    gap: 10px !important;
    flex-wrap: wrap;
    background:  url('../Images/banner-bg.jpg');
    background-repeat: no-repeat;
    background-position:  center;
    background-size: cover;
    padding-top: 60px !important;
    height:95vh;
}

.home__banner>div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.home__banner>div>h1{
    margin-top: 10px !important;
    padding-bottom:0px !important
}

.home__title{
   min-width: 300px !important;
   font-size:40px !important;
   text-align: center;
   padding-bottom: 60px !important;
}



.ms-home-banner .ms-check-icon {
    font-size: 20px;
    position: relative;
    top: 2px;
    color: var(--ms-second-color);
}
.ms-home-banner h6 {
    color: #212529;
    opacity: 0.8;
    font-family: var(--ms-second-font);
}
/*----- 02.b. Stand Apart Section -----*/


/*-------Card Fliper----------------*/

.flip__card{
    position:relative;
    perspective: 1000px; 
    background-color: transparent !important;
}

.flip__card__inner{
    position: relative !important;
    width: 100%;
    height: 100%;
    transition: transform 0.8s;
    transform-style: preserve-3d;
}

.flip__card:hover .flip__card__inner{
   transform: rotateY(180deg);
}

.flip__card__front, .flip__card__back{
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    width: 100%;
    height: 100%;
}


.flip__card__back{
    width: fit-content;
    height: fit-content;
    position: absolute;
    background-color: white;
    top: 0;
    right: 0;
    width: inherit;
    height: inherit;
    border-radius: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    color: black;
    transform: rotateY(180deg);

}

.flip__card__back>p{
    text-align: center;
    font-size: 18px;
    line-height: 30px;
    width: 80%;
    margin: auto;
}


/* ----findcureways----- */
.ms-cured-section{
    padding: 40px 0;
    /* background: linear-gradient(180deg, rgb(237, 233, 233), #edf2f7); */
    background: #ededef;
}
.carousel-arrow {
    background: rgba(0,0,0,0.4);
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 50%;
    font-size: 18px;
    cursor: pointer;
  }
  
  .carousel-arrow:hover {
    background: rgba(0,0,0,0.6);
  }


/*-------Card Fliper----------------*/


.ms-apart-section {
    padding: 80px 0;
    background: linear-gradient(180deg, rgb(237, 233, 233), #edf2f7);
}
.ms-apart-section .ms-icon-box {
    padding:50px 30px;
    border-radius: 20px;
    box-shadow: 0 10px 30px #eaf2f1;
    transition: all 500ms ease;
    text-align: center;
    min-width: 300px;
    min-height: 280px;
    background:url('../Images/icon-back-img.png') rgba(255,255,255,1);
    background-repeat: no-repeat;
    background-position:-15%;
}
.ms-cureways-section{
    padding: 80px 0;
    background:  #018C92;
}
.ms-cureways-section .ms-icon-box {
    padding:50px 30px;
    border-radius: 20px;
    box-shadow: 0 10px 30px #eaf2f1;
    transition: all 500ms ease;
    text-align: center;
    min-width: 300px;
    min-height: 280px;
    background:url('../Images/icon-back-box.png')   rgb(148 227 231);
    background-size:cover;
    background-repeat: no-repeat;
    background-position:-15%;
}
.ms-apart-section .ms-icon-box img {
    width: 80px;
}
.ms-apart-section .ms-icon-box h5 {
    font-size: 18px;
    line-height: 1.5em;
}
.ms-cureways-section .ms-icon-box h4{
    color: #006076;
}
.ms-cureways-section .ms-icon-box img{
    width: 80px;
}
/* #006076 */

/* .ms-apart-section .ms-icon-box {
    min-height: 310px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
} */
/*----- 02.c. Connect Section -----*/
.ms-content-section {
    background:  rgba(255,255,255,0.6);
    background-blend-mode: overlay;
    padding: 0px 0;
    overflow: hidden;

}

.content__logo>img{
    width: 55px !important;
    margin: 30px 0px;
}


.margin__first{
    margin-top: 30px !important;
}

.margin__second{
    margin: 60px 0px !important;
}

.fade__img{
    height: 100%;
    background-blend-mode:multiply;
}

.ms-content-section .ms-check-icon {
    color: var(--ms-second-color);
    position: relative;
    top: 4px;
    font-size: 20px;
}
.ms-content-section h6 {
    color: var(--ms-third-color);
    line-height: 1.65em;
    font-size: 18px;
    font-family: var(--ms-second-font);
}
/*----- 02.d. Top Articles Section -----*/

/* ********** Article in new window **************  */

.article_iframe_container{
    height: 100vh !important;
    width: 100vw !important;
}

.ms-article-section {
    padding: 80px 0;
    background: #f6f6f6;
}
.ms-article-box {
    border-radius: 30px;
    overflow: hidden;
    box-shadow: 0 10px 30px #eaf2f1;
    transition: 0.3s all linear;
}
.ms-article-box:hover {
    box-shadow: 0 10px 30px rgb(0 0 0 / 15%);
}
.ms-article-box .ms-featured-img img {
    transition: 0.3s all linear;
}
.ms-article-box .ms-featured-img:before {
    background: var(--ms-primary-color);
    position: absolute;
    content: '';
    width: 100%;
    height: 0%;
    left: 0px;
    top: 0px;
    opacity: 0.8;
    z-index: 1;
    transition: all 500ms ease;
}
.ms-article-box:hover .ms-featured-img:before {
    height: 100%;
}
.ms-article-box:hover .ms-featured-img img {
    transform: scale(1.1);
    cursor: pointer;
}
.ms-article-content {
    padding: 30px;
}
.ms-category-meta {
    position: absolute;
    bottom: 142px;
    left: 30px;
    z-index: 0;
}
.ms-category-meta a {
    background: var(--ms-second-color);
    font-size: 14px;
    color: #fff;
    padding: 7px 15px;
    border-radius: 10px;
}
.ms-author-meta img {
    width: 40px;
    height: 40px;
    margin-right: 10px;
    border-radius: 50%;
}
.ms-author-meta p {
    color: #2d2929;
    font-size: 15px;
    font-weight: 600;
}
.ms-description p {
    font-size: 15px;
    color: var(--ms-third-color);
}
.ms-description a {
    font-size: 16px;
}
.ms-description a:before, .ms-description a:after {
    content: '';
    display: block;
    position: absolute;
    bottom: -2px;
    height: 2px;
    width: 0;
    transition: width 0s ease;
}
.ms-description a:before {
    left: 0;
}
.ms-description a:after {
    right: 0;
    background: var(--ms-primary-color);
    transition: width .5s ease;
}
.ms-description a:hover {
    color: var(--ms-primary-color);
}
.ms-description a:hover:before {
    width: 100%;
    background: var(--ms-primary-color);
    transition: width .5s ease;
}
.ms-description a:hover:after {
    width: 100%;
    background: transparent;
}

/* .ms-content-section {
    overflow: hidden;
  } */
  
  .scrollable-content {
    height: 100vh;
    overflow-y: auto;
    padding-right: 2rem;
    scrollbar-width: none;  /* For Firefox */
    -ms-overflow-style: none;  /* For Internet Explorer and Edge */
  }
  
  .scrollable-content::-webkit-scrollbar {
    width: 0px;
    background: transparent;  /* For Chrome, Safari, and Opera */
  }
  
  .content-section {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .fixed-image-container {
    position: sticky;
    top: 0;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .fixed-image {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    position: absolute;
  }
  
  .fixed-image.active {
    opacity: 1;
  }
  
  @media (max-width: 992px) {
    .scrollable-content,
    .fixed-image-container {
      height: auto;
    }
  
    .content-section {
      min-height: auto;
      margin-bottom: 2rem;
    }
  
    .fixed-image-container {
      position: static;
      margin-bottom: 2rem;
    }
  
    .fixed-image {
      position: static;
      opacity: 1;
      margin-bottom: 2rem;
    }
  }

@media (max-width:550px) {

    /*==================== 02. Homepage CSS ====================*/
    /*----- 02.a. Banner Section -----*/
    .ms-home-banner {
        padding: 80px 10px;
        padding-bottom: 0px !important;
    }

    .ms-cured-section {
        padding: 60px 10px;
        height: 100%;

    }
    /*----- 02.b. Stand Apart Section -----*/
    .ms-apart-section {
        padding: 60px 10px;
    }
    /*----- 02.c. Connect Section -----*/
    .ms-connect-section {
        padding: 60px 10px;
    }
    
    /*----- 02.d. Top Articles Section -----*/
    .ms-article-section {
        padding: 60px 10px 30px;
    }
    .ms-article-box {
        margin-bottom: 30px;
    }


}

@media (min-width:1210px) {

    .home__title{
        font-size: 60px !important;
        width:550px !important;
        max-width: fit-content;
        text-align: start;
    }

    .home__swiper{
        width: 60% !important;
        position: absolute !important;
        margin-top: 0px !important;
        right: 0px;
        bottom: 0px;
    }

    .ms-home-banner{
       position: relative;
       padding-top: 0px !important;
    }

    .home__swiper__box{
        display: block !important;
    }
    
    .home__btn{
        margin:0px !important;
     }
     
     .home__banner>div{
        margin-top: 2rem !important;
            display: block;
    }
    .home__banner>div>h1{
        margin-top:2rem !important;
        padding-bottom:30px !important
    }

    .content__logo>img{
        width: 70px !important;
        margin: 30px 0px;
    }
    

}

@media screen and (min-width: 780px) and (max-width: 1200px){
    .home__title{
        font-size: 50px !important;
        text-align: start;
    }

    .home__swiper{
        width: 55% !important;
        position: absolute !important;
        margin-top: 0px !important;
        right: 0px;
        bottom: 0px;
    }

    .ms-home-banner{
       position: relative;
       padding-top: 0px !important;
    }

    .home__swiper__box{
        display: block !important;
    }
    
    .home__btn{
        margin:0px !important;
     }
     
     .home__banner>div{
        margin-top: 2rem !important;
            display: block;
    }
    .home__banner>div>h1{
        margin-top:2rem !important;
        width: 60% !important;
        padding-bottom:30px !important
    }


    .content__logo>img{
        width: 60px !important;
        margin: 30px 0px;
    }
    
}

.getapp-container {
    background: transparent;
    border: none;
}
  
.getapp-container img {
    width: 150px;
    cursor: pointer;
}
.getapp-contain{
    background: transparent;
    border: none;
    img{
        width: 130px;
        cursor: pointer; 
        border-radius: 5px;
    }
}
@keyframes fade-in-up {
    0% { opacity: 0; }
    100% { transform: translateY(0); opacity: 1; }
  }
  
  .video {
    position: fixed;
    bottom: 50px; /* Adjust as needed */
    right: 5%; /* Move to the center horizontally */
    transform: translateX(5%); /* Center the video */
    z-index: 9999; /* Ensure it's above other content */
  
  }
  


  .close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
  .image-container {
    position: relative;
    display: inline-block; /* Ensures images are displayed in-line */
  }
  .confuse-image {
    height: auto; /* Maintain aspect ratio */
  }
  
  .image-contain {
    display: flex;
    align-items: flex-start; /* Align items to the start of the flex container (top of container) */
  }
  
  .doctor-image {
    height: 220px;
    
  }
  
  .stats-image {
    height: 220px;
    margin-left: -68px; /* Adjust the negative margin to your desired positioning */
    margin-top: 20px; /* Adjust the top margin to create space between the images */
  }
  
  
   
  @media screen and (max-width: 768px) {
    .confuse-image{
        left: 50%; /* Adjust as needed */
        bottom: auto;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);

    }
    .image-container {
        display: flex;
    }
    .doctor-image {
        height: 190px;
    }
    
  }
  .cred {
    .dino{ 
        height: 200px;
        /* width: 320px !important; */
        float:unset;
        margin-top: -37px;
        margin-left: -55px;
        margin-bottom: -147px;
    }
  }
  .fred{
    .fino{
        height: 180px;
        /* width: 320px !important; */
        float: left;
        margin-left: -20px;
        margin-right: -36px;
    }
  }

  .handlrs {
    background-color: #ffa000;
    -webkit-border-radius: 10px;
      margin-left: 10px;
    border-radius: 10px;
    border: none;
    color: #ffffff;
    cursor: pointer;
    display: inline-block;
    font-family: Arial;
    font-size: 20px;
    padding: 5px 10px;
    text-align: center;
    text-decoration: none;
    -webkit-animation: glowing 1400ms infinite;
    -moz-animation: glowing 1400ms infinite;
    -o-animation: glowing 1400ms infinite;
    animation: glowing 1400ms infinite;
    }
    @-webkit-keyframes glowing {
        0% { background-color: #ffcb25; -webkit-box-shadow: 0 0 3px #ffcb25; }
        50% { background-color: #ffa000; -webkit-box-shadow: 0 0 40px #ffa000; }
        100% { background-color: #ffcb25; -webkit-box-shadow: 0 0 3px #ffcb25; }
      }
      
      @-moz-keyframes glowing {
        0% { background-color: #ffcb25; -moz-box-shadow: 0 0 3px #ffcb25; }
        50% { background-color: #ffa000; -moz-box-shadow: 0 0 40px #ffa000; }
        100% { background-color: #ffcb25; -moz-box-shadow: 0 0 3px #ffcb25; }
      }
      
      @-o-keyframes glowing {
        0% { background-color: #ffcb25; box-shadow: 0 0 3px #ffcb25; }
        50% { background-color: #ffa000; box-shadow: 0 0 40px #ffa000; }
        100% { background-color: #ffcb25; box-shadow: 0 0 3px #ffcb25; }
      }
      
      @keyframes glowing {
        0% { background-color: #ffcb25; box-shadow: 0 0 3px #ffcb25; }
        50% { background-color: #ffa000; box-shadow: 0 0 40px #ffa000; }
        100% { background-color: #ffcb25; box-shadow: 0 0 3px #ffcb25; }
      }
.handlrs:hover{
    animation: none;
}
  
/* General layout styles */
.care-banner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; /* Center content vertically */
    gap: 0;
    background-color: #009185;
    padding: 20px 0;
    width: 100%;
    height: 91vh; /* Default height */
    border-radius: 0; /* Reset all corners */
    border-bottom-left-radius: 2%; /* Only bottom-left corner curved */
    border-bottom-right-radius: 2%; 
  }
  .care-container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  /* Row styles */
  .row.center-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap; /* Ensures responsiveness */
  }
  
  /* Heading styles */
  .care-heading {
    font-weight: 600;
    color: #fff;
    font-size: 2.5rem;
    margin-bottom: 1.6rem;
    margin-top: 15.5rem;
    text-align: center;
  }
  
  /* Swiper styles */
  .care-swiper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
  }
  
  .care-swiper-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 800px;
    overflow: hidden;
    height: auto; /* Adjust height dynamically */
    margin-top: 0; /* Adjust vertical spacing */
    position: relative;
  }
  
  .care-image {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    overflow: hidden;
    border-radius: 10px;
    margin-top: 30px;
  }
  
  .care-image img {
    width: 100%;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  .care-download {
    margin-top: 2rem;
    text-align: center;
  }
  
  /* Button styles */
  .care-download-btn {
    display: inline-block;
    background-color: #fff; /* Button background color */
    padding: 10px 20px;
    border-radius: 12px;
    border: 4px solid #009185; /* White border */
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  /* Hover effect */
  
  
  /* Text inside button */
  .care-download-text {
    font-weight: 600;
    color: #009185; /* White text */
    margin: 0;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  

  
  /* Icons inside button */
  .care-download-icon {
    margin-left: 10px;
    width: 24px;
    height: 24px;
    
  }
  /* Responsive Design for Smaller Screens */
  @media (max-width: 900px) {
    .care-banner {
      height: 91vh; /* Keep height consistent */
      padding: 15px 0;
    }
  
    .care-heading {
      font-size: 2rem;
      margin-top: 16rem;
    }
  }
  
  @media (max-width: 550px) {
    .care-banner {
      height: 77vh; /* Maintain same height */
      padding: 15px 0;
    }
  
    .care-heading {
      font-size: 1.3rem;
      margin-top: 16rem;
    }
  }
  
  @media (max-width: 360px) {
    .care-banner {
      height: 88vh; /* Same height to maintain consistency */
      padding: 15px 0;
    }
  
    .care-heading {
      font-size: 1.2rem;
      margin-top: 13rem;
    }
  }