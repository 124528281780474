body {
    margin: 0;
    overflow: hidden; /* Prevent page scroll */
  }
  
  #parallax-scroll-box {
    scrollbar-width: thin; /* Modern thin scrollbar */
    scrollbar-color: rgba(0, 0, 0, 0.5) transparent;
  }
  
  #parallax-scroll-box::-webkit-scrollbar {
    width: 8px; /* Thin scrollbar */
  }
  
  #parallax-scroll-box::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.5); /* Dark scrollbar handle */
    border-radius: 4px; /* Rounded handle */
  }
  
  #parallax-scroll-box::-webkit-scrollbar-track {
    background: transparent; /* Transparent track */
  }
  
.video-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: #f8f8f8;
    padding: 20px;
  }
  
  .section-video {
    width: 90%;
    max-width: 800px;
    margin-bottom: 2rem;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .video-box-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1rem;
    width: 90%;
    max-width: 800px;
  }
  
  .video-box {
    flex: 1;
    padding: 1rem;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .video-box h2 {
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }
  
  .video-box p {
    font-size: 0.85rem;
    color: #555;
  }
  
  /* Responsive Design */
  @media (max-width: 1024px) {
    .section-video {
      width: 85%;
    }
  
    .video-box-container {
      flex-direction: column;
    }
  
    .video-box {
      margin-bottom: 1rem;
    }
  }
  
  @media (max-width: 768px) {
    .video-section h1 {
      font-size: 1.8rem;
    }
  
    .section-video {
      width: 95%;
    }
  
    .video-box h2 {
      font-size: 0.9rem;
    }
  
    .video-box p {
      font-size: 0.8rem;
    }
  }
  
  @media (max-width: 480px) {
    .video-section h1 {
      font-size: 1.5rem;
    }
  
    .video-box h2 {
      font-size: 0.85rem;
    }
  
    .video-box p {
      font-size: 0.75rem;
    }
  }
  /* Add this in your CSS file */

/* Default styles (Desktop) */
.hip-pro-plus-container {
    margin-left: 4rem;
    margin-top: 0;
  }
  
  .hip-pro-plus-heading {
    color: #fff;
    font-weight: 800;
    font-size: 36px;
    width: 70%;
    margin-left: 70px;
    text-align: left;
  }
  .hip-pro-plus-heading2 {
    color: #fff;
    font-weight: 800;
    font-size: 36px;
    width: 70%;
    margin-left: 70px;
    text-align: left;
  }
  .hip-pro-plus-content {
    text-align: left;
  }
  
  .hip-pro-plus-text {
    color: #fff;
    font-weight: bold;
    font-size: 1rem;
    margin-top: 0.9rem;
    width: 53%;
    margin-left: 62px;
    margin-right: 0;
    text-indent: 0;
  }
  
  .hip-pro-plus-contact {
    text-align: center;
    margin-bottom: 0;
  }
  
  .hip-pro-plus-contact-text {
    color: #fff;
    display: block;
    font-size: 24px;
    margin-top: 0.5rem;
    font-weight: 500;
    width: 50%;
    margin-left: 70px;
    margin-right: 0;
  }
  
  .hip-pro-plus-image {
    text-align: center;
    margin-top: -1rem;
    height: 327px;
    margin-left: -383px;
    width: auto;
  }
  @media (max-width: 1320px) {
    .hip-pro-plus-image {
       
        margin-left: -243px;
       
      }
  }
  @media (max-width: 1120px) {
    .hip-pro-plus-image {
       
        margin-left: -143px;
       
      }
  }
  /* Tablet styles */
  @media (max-width: 1024px) {
    .hip-pro-plus-heading {
        font-size: 24px;
        width: 100%;
        margin-left: -16px;
        text-align: left;
      }
      .hip-pro-plus-heading2
      {
        font-size: 24px;
        width: 100%;
        margin-left: -16px;
        text-align: left;
      }
  
    .hip-pro-plus-text {
        font-size: 18px;
        width: 86%;
        margin-left: -11px;
    }
  
    .hip-pro-plus-contact-text {
        font-size: 18px;
        width: 82%;
        margin-left: -12px;
    }
  
    .hip-pro-plus-image {
        height: 270px;
        margin-left: -105px;
        margin-top: 2px;
    }
  }
  
  /* Mobile styles */
  @media (max-width: 768px) {
    .hip-pro-plus-container {
      margin-left: 0;
      margin-top: -2rem;
    }
  
    .hip-pro-plus-heading {
      font-size: 20px;
      width: 95%;
      margin-left: 0;
      text-align: center;
    }
  
    .hip-pro-plus-content {
      text-align: center;
    }
  
    .hip-pro-plus-text {
      font-size: 16px;
      width: 97%;
      margin-left: auto;
      margin-right: 46px;
    }
  
    .hip-pro-plus-contact-text {
      font-size: 14px;
      width: 85%;
      margin-left: auto;
      margin-right: 34px;
    }
  
    .hip-pro-plus-image {
      height: 246px;
      width: 54%;
      margin-left: 0;
      margin-bottom: 50px;
    }
  }
  @media (max-width: 450px) {
    .hip-pro-plus-image {
        height: 244px;
        width: 68%;
        margin-left: 0;
        margin-bottom: 20px;
      }
  }
  @media (max-width: 350px) {
    .hip-pro-plus-image {
        height: 244px;
        width: 85%;
        margin-left: 0;
        margin-bottom: 15px;
      }
  }
  /* General container styling */
.terms-container {
    padding: 2rem;
    background-color: #fff;
    color: #333;
    font-family: Arial, sans-serif;
    line-height: 1.6;
  }
  
  /* Heading styling */
  .terms-heading {
    font-weight: 800;
    font-size: 36px;
    margin-bottom: 1rem;
    color: #0f8894; /* Converted "rgb(15, 136, 148)" to hexadecimal */
    text-align: center; /* Adjusted to "center" as per the provided inline style */
    font-family: "Poppins", sans-serif; /* Added "Poppins" font-family */
  }
  
  
  @media (max-width: 768px) {
    .terms-heading {
      font-size: 20px;
      text-align: center;
      margin: 0 auto;
      padding: 0 10px;
    }
  }
  
  /* Content container styling */
  .terms-content {
    max-width: 1800px;
    margin: 0 auto;
    padding: 1rem;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  /* Individual term item styling */
  .terms-item {
    margin-bottom: 1rem;
    font-size: 0.85rem;
  }
  
  /* Responsive adjustments */
  @media (max-width: 1024px) {
    .terms-content {
      padding: 1rem 2rem;
    }
  
    .terms-item {
      font-size: 0.9rem;
    }
  }
  
  @media (max-width: 768px) {
    .terms-content {
      padding: 1rem;
    }
  
    .terms-item {
      font-size: 0.8rem;
    }
  }
  