/* Section container */
.treatment-mini {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: linear-gradient(180deg, rgb(237, 233, 233), #edf2f7);
    box-sizing: border-box;
}

/* Heading and description */
.heading-description1 {
    text-align: center;
    margin-bottom: 30px;
    max-width: 800px;
}

.heading-description1 h2 {
    font-size: 2.5rem;
    color: #141414;
    margin-bottom: 10px;
}

.heading-description1 p {
    font-size: 1.2rem;
    color: #646464;
    margin: 0 auto;
}

/* Rectangle container for horizontal layout with six rectangles per row */
.mini-rectangle-container {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 20px;
    max-width: 1400px;
    width: 100%;
    margin-top: 20px;
}

/* Rectangle styling */
.mini-rectangle {
    background: linear-gradient(to bottom, #fff 60%, #01A09E 60%);
    width: 100%;
    height: 250px;
    padding: 20px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    gap: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.mini-rectangle:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Plus icon styling */
.plus-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    color: #018C92;
    font-size: 1.5em;
}

/* Image styling inside rectangle */
.mini-rectangle img {
    width: 80%;
    height: auto;
    max-height: 130px;
    object-fit: cover;
    margin-bottom: 15px;
}

/* Name text styling */
.name {
    font-size: clamp(0.9rem, 1.5vw, 1.2rem);
    color: #f9fafa;
    text-align: center;
    margin-top: 10px;
}

/* More Treatments button styling */
.more-treatments {
    margin-top: 30px;
    padding: 12px 20px;
    background-color: #005c74;
    color: #fff;
    font-size: 1.1rem;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s ease;
    display: inline-block;
    max-width: 250px;
    width: 100%;
    text-align: center;
}

.more-treatments:hover {
    background-color: #003f52;
}

/* Media Queries */
@media (max-width: 1200px) {
    .mini-rectangle-container {
        grid-template-columns: repeat(5, 1fr);
    }
}

@media (max-width: 1024px) {
    .mini-rectangle-container {
        grid-template-columns: repeat(4, 1fr);
    }
    .mini-rectangle {
        height: 230px;
    }
    .more-treatments {
        width: 90%;
    }
}

@media (max-width: 768px) {
    .mini-rectangle-container {
        grid-template-columns: repeat(3, 1fr);
    }
    .mini-rectangle {
        height: 220px;
    }
}

@media (max-width: 480px) {
    .mini-rectangle-container {
        grid-template-columns: repeat(2, 1fr);
    }
    .mini-rectangle {
        height: 200px;
    }
    .more-treatments {
        width: 90%;
    }
    .heading-description1 h2 {
        font-size: 1.5rem;
        
    }
}