/* General Styling */
/* Navbar Row Styling */

.navbar-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
}

/* Search Bar & Button Container */
.nav-right {
  display: flex;
  align-items: center;
  gap: 15px; /* Spacing between search and button */
}

/* Search Bar */
.search-container {
  display: flex;
  align-items: center;
  max-width: 250px; /* Reduced width */
  width: 100%;
}

.search-wrapper {
  position: relative;
  width: 100%;
}

.search-input {
  width: 100%;
  padding: 8px 35px 8px 12px; /* Smaller padding */
  border: 1px solid rgb(0, 92, 116);
  border-radius: 20px;
  font-size: 14px;
  background-color: #fff;
  color: rgb(0, 92, 116);
  transition: border-color 0.3s, box-shadow 0.3s;
}

.search-input:focus {
  outline: none;
  border-color: rgb(0, 92, 116);
  box-shadow: 0 0 5px rgba(0, 92, 116, 0.5);
}

.search-icon {
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
  color: rgb(0, 92, 116);
  font-size: 14px;
  cursor: pointer;
}

/* Button Styling */
.ms-header-btn {
  padding: 5px 15px;
  font-size: 14px;
  font-weight: 500;
  background-color: var(--ms-primary-color);
  color: white;
  border-radius: 20px;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  transition: background-color 0.3s ease-in-out;
}

.ms-header-btn:hover {
  background-color: var(--ms-primary-hover-color);
}

/* Responsive Adjustments */
@media (max-width: 1024px) {
  .search-container {
    max-width: 200px;
  }
  
  .search-input {
    font-size: 13px;
    padding: 6px 30px 6px 10px;
  }

  .search-icon {
    font-size: 12px;
  }

  .ms-header-btn {
    font-size: 13px;
    padding: 4px 12px;
  }
}


.search-container {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
  padding: 10px;
}

.search-wrapper {
  position: relative;
  width: 100%;
}

.search-input {
  width: 100%;
  padding: 12px 40px 12px 15px;
  border: 1px solid rgb(0, 92, 116);
  border-radius: 25px;
  font-size: 16px;
  background-color: #fff;
  color: rgb(0, 92, 116);
  transition: border-color 0.3s, box-shadow 0.3s;
}

.search-input:focus {
  outline: none;
  border-color: rgb(0, 92, 116);
  box-shadow: 0 0 6px rgba(0, 92, 116, 0.5);
}

.search-icon {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  color: rgb(0, 92, 116);
  font-size: 18px;
  cursor: pointer;
  transition: color 0.3s;
}



.search-wrapper:hover .search-icon {
  color: white;
}

.nav-item {
  list-style: none;
  margin: 0;
  padding: 0;
}
.nav-menu {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 0;
  margin: 0;
  list-style: none;
}

.nav-item {
  position: relative;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  padding: 8px 12px;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background: white;
  padding: 10px 0;
  list-style: none;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  min-width: 180px;
  display: flex;
  flex-direction: column;
}

.dropdown-item {
  padding: 8px 15px;
  position: relative;
}

.dropdown-link {
  text-decoration: none;
  color: #333;
  font-weight: 500;
  display: block;
  padding: 8px 15px;
}

.sub-dropdown {
  position: absolute;
  top: 0;
  left: 100%;
  background: white;
  padding: 10px 0;
  list-style: none;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  min-width: 180px;
}

.ms-line-slide {
  position: relative;
}

.second-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nav-links {
  display: flex;
  
}

.nav-links a {
  text-decoration: none;
  color: #000;
  font-weight: 600;
  padding: 8px;
}

.mobile-menu-icon {
  display: none;
  font-size: 1.5rem;
  background: none;
  border: none;
  cursor: pointer;
}

.mobile-menu-dropdown {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  background-color: #fff;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}
.mobile-header {
  position: fixed; /* Fixed position so it stays at the top */
  top: 0;
  left: 0;
  width: 100%;
  z-index: 15;
  transition: all 0.4s ease-in-out;
  background: transparent; /* Start as transparent */
}

/* When scrolled, add background color */
.mobile-header__color {
  background-color: white;
}
.mobile-header-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.logo {
  font-size: 1.5rem;
  font-weight: bold;
  text-decoration: none;
  color: #333;
}

.menu-toggle {
  background: none;
  border: none;
  font-size: 1.8rem;
  cursor: pointer;
}

.mobile-menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.menu-close {
  position: absolute;
  top: 15px;
  right: 20px;
  background: none;
  border: none;
  font-size: 2rem;
  color: white;
  cursor: pointer;
}

.mobile-nav {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
}

.mobile-nav a {
  color: white;
  text-decoration: none;
  font-size: 1.2rem;
  font-weight: bold;
}

.mobile-search {
  display: flex;
  align-items: center;
  background: white;
  padding: 10px;
  border-radius: 5px;
  margin-top: 20px;
  width: 80%;
}

.mobile-search .search-icon {
  font-size: 1.5rem;
  color: #555;
  margin-right: 10px;
}

.mobile-search input {
  border: none;
  width: 100%;
  font-size: 1rem;
  outline: none;
}

.mobile-actions {
  margin-top: 20px;
}

@media (max-width: 1024px) {
  .search-container {
    max-width: 500px;
    padding: 8px;
  }

  .search-input {
    font-size: 14px;
    padding: 10px 35px 10px 12px;
  }

  .search-icon {
    font-size: 16px;
  }
}



@media (max-width: 768px) {
  .search-container {
    max-width: 100%;
    padding: 5px;
  }

  .search-input {
    font-size: 14px;
    padding: 8px 35px 8px 10px;
  }

  .search-icon {
    font-size: 14px;
    right: 10px;
  }

  .navbar-row {
    flex-wrap: wrap;
    justify-content: center;
  }

  .navbar-brand {
    margin-bottom: 10px;
  }

  .search-container {
    width: 100%;
    padding: 0 10px;
  }

  .be-partner-btn {
    margin-top: 10px;
  }

  .nav-links {
    display: none;
  }
}

@media (max-width: 480px) {
  .search-container {
    max-width: 100%;
    padding: 5px;
  }

  .search-input {
    font-size: 12px;
    padding: 8px 30px 8px 8px;
  }

  .search-icon {
    font-size: 12px;
    right: 8px;
  }
}
/* Hide mobile header on larger screens (900px and above) */
@media (min-width: 975px) {
  .mobile-header {
    display: none;
  }
}

/* Display mobile header on smaller screens */
@media (max-width: 975px) {
  .mobile-header {
    display: block;
  }
  .navbar-brand {
    margin-bottom: 20px;
  }
}

/* Mobile Header Container */
.mobile-header {
  position: fixed; /* Fixed at the top */
  top: 0;
  left: 0;
  width: 100%;
  z-index: 15;
  transition: all 0.1s ease-in-out;
}

/* ---------- Before Scroll (Default) ---------- */
/* Top row initially has rgb(1, 160, 158) background */
.mobile-header__top {
  background: #009185;
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #eee;
}

/* Bottom row initially has white background */
.mobile-header__bottom {
  background: #fff;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  border-top: 1px solid #eee;
  height: 50px;
}

/* ---------- After Scroll ---------- */
/* When mobile-header has the extra class, swap backgrounds */
.mobile-header.mobile-header__color .mobile-header__top {
  background: #fff;
}
.mobile-header.mobile-header__color .mobile-header__bottom {
  background: #009185;
}

/* Logo image */
.mobile-logo img {
  height: 40px;
}

/* Menu Toggle Icon */
.menu_toggle {
  cursor: pointer;
}

/* Dropdown Menu Content */
.menu__toggle__content {
  padding: 1rem;
  border-top: 1px solid #eee;
  background-color: #fff;
}

/* Navigation List Styles */
.nav-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.nav-item {
  margin-bottom: 0.5rem;
}

.nav-link {
  text-decoration: none;
  color: #333;
  font-size: 16px;
  padding-left: 18px;
}

/* Dropdown Toggle (for submenus) */
.dropdown-toggle {
  font-size: 17px;
  font-weight: 600;
  cursor: pointer;
  margin-left: 18px;
  margin-right: 20px;
}

/* Mobile Dropdown List */
.mobile-dropdown-list {
  list-style: none;
  padding-left: 1rem;
  margin-top: 0.5rem;
}

/* Sub-dropdown (nested dropdown) */
.sub-dropdown {
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 101;
  padding: 10px 0;
}

/* Download Button Styles */
.ms-secondary-btn.ms-header-btn {
  padding: 5px 30px;
  font-size: 14px;
  display: inline-block;
  /* margin-left: 7px;
  color: #fff;
  text-decoration: none;
  border-radius: 4px; */
  
  background-color: rgb(0, 92, 116);
  border-radius: 5px;
}

/* Search Container and Input */
.search-container2 {
  flex: 1;
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 25px; /* Smooth rounded edges */
  padding: 0.1rem;
  background-color: #f9f9f9;
  transition: border-color 0.1s ease;
  position: relative; /* Needed for absolute positioning of the icon */
}

.search-container2:focus-within {
  border-color: #019a9e; /* Highlight border on focus */
}

.search-container2 input {
  flex: 1;
  border: none;
  outline: none;
  background: transparent;
  font-size: 14px;
  color: #333;
  font-family: Arial, sans-serif;
  padding: 5px 5px 5px 5px; /* Extra padding on the right for the icon */
  width: 100%;
  border-radius: 25px;
}

.search-container2 .search-icon {
  position: absolute;
  right: 12px; /* Move the icon inside the search bar */
  color: #888;
  font-size: 18px;
  cursor: pointer;
}


/* Join Button */

@media (max-width: 975px) {
  .desktop-header {
    display: none;
  }
  .MuiSvgIcon-root {
    fill: currentColor;
    width: 2em;
    height: 1em;
    display: inline-block;
    font-size: 1.5rem;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    flex-shrink: 0;
    margin-bottom: -28px;
  }
}
/* .MuiSvgIcon-root {
  fill: currentColor;
  width: 2em;
  height: 1em;
  display: inline-block;
  font-size: 1.5rem;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-shrink: 0;
  margin-bottom: -28px;
} */
@media (max-width: 975px) {
  .MuiSvgIcon-root {
    fill: currentColor;
    width: 2em;
    height: 1em;
    display: inline-block;
    font-size: 1.5rem;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    flex-shrink: 0;
    margin-bottom: -8px;
  }
  }
  .search-button {
    position: absolute;
    right: 12px;
    top: 75%;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
    font-size: 20px;
    color: #555;
    display: flex;
    align-items: center;
  }
  
  /* Media Query for screens 975px and below */
  @media (max-width: 975px) {
    .search-button {
      font-size: 14px; /* Reduce icon size */
      right: 8px; /* Adjust position */
      top: 50%; /* Move slightly up */
    }
  }
  @media (max-width: 975px) {
    .search-button {
      font-size: 14px; /* Reduce icon size */
      right: 8px; /* Adjust position */
      top: 50%; /* Move slightly up */
    }
  }
  @media (max-width: 768px) {
    .search-button {
      font-size: 14px; /* Reduce icon size */
      right: 8px; /* Adjust position */
      top: 75%; /* Move slightly up */
    }
  }
  .spinner {
    width: 30px;
    height: 30px;
    border: 4px solid rgb(0, 92, 116);
    border-top: 4px solid #009185; /* Blue color */
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  