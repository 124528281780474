.signup{
    height: 100vh;
    width: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
    margin: auto;

}

.signup__form>p{
    font-size: 24px;
    text-align: center;
    margin-bottom: 20px;
}


.signup__form>p>span>img{
    width: 100px;
}

.signup__form>h1{
    text-align: center;
    font-weight:500 !important;
    font-size: 16px;
    margin-bottom: 20px !important;
    font-family: var(--roboto);
}

.signup__form{
    width: 400px;
    padding: 30px;
    border-radius:5%;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.signup__form>div{
    margin: 10px 0px;
}

.signup__botton{
    background-color: #EC2A32!important;
    color:var(--white); 
    font-size: 17px !important;
    /* font-weight: 600; */
    outline: none;
    border: none;
    border: none;
}

.signup__botton:focus{
    box-shadow: none;
}

.signup__botton__correct{
    background-color: #01B9F0 !important;
    border: none;
}

.signup__botton:hover{
    background-color: transparent !important;
    color: #EC2A32 !important;
    border: 1px solid #EC2A32 ;
    transition: all 0.3s ease-in;
    font-weight: bold;
}

.checkbox{
    font-size: 11px !important;
    /* padding-left: 20px; */
    margin-top: 6px;
}


/* ************* Button Animation ********************  */


.signup__validation{
    font-size: 12px !important;
    font-weight:500;
    color:red;
    margin: 10px;
    transition: all 0.5s ease-in;
}

input{
    font-size:14px !important;
    /* height: 50px !important; */
}

input:focus{
    box-shadow: none;
}

label{
    font-size: 14px;   
}

