/* CSS Variables for Colors */
:root {
    --primary-color: #0277bd;
    --secondary-color: #006bb3;
    --highlight-bg: #f0f8ff;
    --table-hover-bg: #e0f2ff;
    --text-dark: #333;
    --text-light: #555;
    --border-color: #ddd;
    --box-shadow: rgba(0, 0, 0, 0.1);
}

/* General Container */
.outer-container {
    /* margin: 10px auto;
    padding: 10px; */
    font-family: Arial, sans-serif;
    max-width: 100%;
}

.inner-container {
    border: 1px solid var(--border-color);
    border-radius: 10px;
    padding: 20px;
    background: linear-gradient(180deg, #fff );
    box-shadow: 0 4px 6px var(--box-shadow);
}

/* Hospital Page Layout */
.hospital-page {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 20px; /* Adjusted for spacing between sections */
}


.hospital-left {
  display: flex;
  flex-direction: column;
}

/* Highlight Right Section */
.hospital-right {
  flex: 1;
  min-width: 300px;
  background-color: rgb(229, 227, 227);
  border-radius: 10px;
  padding: 40px;
  box-shadow: 0 4px 6px var(--box-shadow);
  margin-top: -60px;
  color: #01A09E;
  overflow-y: auto; /* Enable scrolling if content exceeds the height */
  max-height: 60vh; /* Decreased height to 60% of viewport height (adjust as needed) */
}


/* Hospital Header */
.hospital-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 30px;
    margin-top: -50px;
    color: #01A09E;
    gap: 10px;
}
.hospital-logo2 {
  width: 150px;
  height: 150px;
  object-fit: contain;
  margin-top: -150px; /* Adjust positioning */
}
.hospital-name {
  margin: 0; /* Remove default margin */
  font-size: 30px;
  font-weight: bold;
    color: var(--text-dark);
    margin-top: -20px;
    color: #01A09E;
}

.hospital-city {
  font-size: 18px;
  font-weight: normal;
  color: #666; /* Grey color for city */
}

.hospital-rating {
  font-size: 16px;
  color: #FFBF00; /* Gold color for stars */
  margin-left: 10px;
}
 
 
  .hospital-logo {
    width: 140px; 
    height:140px;
margin-top: -35px;
margin-bottom: 30px;
    /* border: 4px solid #ddd;  */
  }
   
.recommendation-logo {
    background-color: #ffcc00; /* Yellow background */
    color: #333; /* Dark text color */
    padding: 0.5em 1em; /* Padding for a rectangle shape */
    border-radius: 8px; /* Slightly rounded edges */
    font-size: 0.9em; /* Adjust font size */
    font-weight: bold; /* Make text stand out */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for a 3D effect */
  }
/* Hospital Image */
.hospital-gallery {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    gap: 20px;
  }
  
  .main-image-container {
    flex: 3;
    display: flex;
    align-items: center;
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: center; /* Center the main image */
    margin-bottom: 10px; 
  }
  
  .hospital-image {
    width: 100%;
    max-height: 400px;
    object-fit: cover;
    border-radius: 10px;
    border: 1px solid var(--border-color);
    box-shadow: 0 4px 6px var(--box-shadow);
    margin-top: -35px;
  }
  
  .thumbnail-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 35px;
    align-items: center;
    margin-top: -10px;
    overflow-x: auto;
  }
  
  .thumbnail {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 5px;
    border: 1px solid var(--border-color);
    cursor: pointer;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
  }
  
  .thumbnail:hover {
    transform: scale(1.1);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  }
  
  .image-sidebar {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 10px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  
/* Hospital Details */
.hospital-heading {
    font-size: 1.8em;
    font-weight: bold;
    margin-bottom: 15px;
    color: #01A09E;
}

.hospital-details {
    font-size: 1.3em; /* Slight increase in font size for better readability */
    line-height: 1.6;
    color: #01A09E;
}
/* Styling for the unordered list to make it horizontal */
.hospital-details ul {
    display: flex;
    flex-wrap: wrap; /* Allows wrapping to the next line */
    gap: 16px; /* Adds spacing between list items */
    padding: 0; /* Removes default padding */
    margin: 0; /* Removes default margin */
    list-style: none; /* Removes default bullets */
  }
  
  /* Styling for individual list items */
  .hospital-details ul li {
    flex: 1 1 calc(33.33% - 16px); /* Makes each list item take up 1/3 of the width with space for the gap */
    box-sizing: border-box; /* Ensures padding and border are included in the width */
    text-align: center; /* Centers the content within each item (optional) */
  }
  
/* Hospital Table */
.hospital-table-section {
    margin-top: 80px;
    color: #01A09E;
}
/* For horizontal alignment of li elements */
/* For horizontal list with commas */
.horizontal-list {
    display: grid;
    grid-template-columns: repeat(5, 1fr); /* Default to 5 items in a row */
    gap: 15px; /* Space between boxes */
    list-style: none; /* Remove default list styling */
    padding: 0; /* Remove padding */
    margin: 0 auto; /* Center the grid within its container */
    max-width: 100%; /* Ensure it doesn't overflow the container */
    box-sizing: border-box; /* Include padding and border in element width/height */
  }
  
  .horizontal-list li {
    display: flex; /* Use flex for centering content */
    align-items: center;
    justify-content: center;
    background-color: #00a09e; /* Box background color */
    color: #fff; /* Text color */
    height: 120px; /* Fixed height for equal sizing */
    border: 1px solid #ddd; /* Subtle border */
    border-radius: 10px; /* Rounded corners */
    font-size: 1.2rem; /* Font size for readability */
    font-weight: 500; /* Slightly bold text */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    text-align: center; /* Center-align text */
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    padding: 10px; /* Padding inside the box */
    overflow: hidden; /* Prevent text overflow */
    word-wrap: break-word; /* Ensure long words wrap */
  }
  
  .horizontal-list li:hover {
    transform: scale(1.05); /* Slightly enlarge on hover */
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15); /* Enhanced shadow */
  }
  /* General table styles remain the same */
.hospital-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 30px;
    background-color: #fff;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 6px var(--box-shadow);
}

.hospital-table td {
    padding: 18px; /* Slight increase for better spacing */
    border: 1px solid var(--border-color);
    text-align: center;
    cursor: pointer;
    font-size: 1.2em; /* Slight increase for better legibility */
    color: #01A09E;
    transition: background-color 0.3s ease, color 0.3s ease;
}

/* Hover effects */
.hospital-table tr:not(:first-child) td:hover,
.hospital-table tr:not(:first-child) td:focus {
    background-color: var(--table-hover-bg);
    color: var(--secondary-color);
}

/* Active state */
.hospital-table td.active {
    background-color: var(--highlight-bg);
    color: var(--primary-color);
    font-weight: bold;
    border: 1px solid var(--primary-color);
}

.hospital-table td:last-child {
    cursor: default;
}


/* Scroll Sections */
.scroll-section {
    margin-top: 50px;
    padding: 20px;
    border: 1px solid var(--border-color);
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
    transition: background-color 0.3s ease, border 0.3s ease;
    color: #fff;
}

.scroll-section h3 {
    font-size: 2em; /* Increased for better visibility */
    font-weight: bold;
    margin-bottom: 15px;
    color: #01A09E;
    text-transform: uppercase;
}
.scroll-section h2 {
    font-size: 2em; /* Increased for better visibility */
    font-weight: bold;
    margin-bottom: 15px;
    color: #01A09E;
    
}
.doctors-list li {
    color: white; /* Override color for doctors section */
  }
  .location-text {
    color: #01A09E;
  }
  /* Specific styles for specialities-section */
 /* General styling for the specialities section */
.specialities-list {
  list-style: none; /* Remove default list bullets */
  padding: 0;
  margin: 0;
  color: white;
  font-family: 'Arial', sans-serif;
}

.speciality-item {
  display: flex; /* Use flexbox for alignment */
  align-items: center;
  font-size: 1.2rem; /* Font size for readability */
  font-weight: 500;
  margin-bottom: 15px; /* Space between items */
  padding: 5px 0;
  color: #00a09e; /* Text color */
}

.tickmark {
  color: #00a09e; /* Color of the check mark */
  font-size: 1.5rem; /* Larger tick mark */
  margin-right: 10px; /* Space between tick mark and the point text */
}

.speciality-item:hover {
  transform: scale(1.05); /* Slightly enlarge on hover */
  transition: transform 0.2s ease;
}

/* Mobile responsiveness (if needed for smaller screens) */
@media (max-width: 768px) {
  .specialities-list {
    font-size: 1rem; /* Slightly smaller font size for mobile */
  }
}

  .top-level-li {
    cursor: pointer;
    font-weight: 600;
    color: #01A09E;
  }
  
  .dropdown-li {
    color: #01A09E;
  }
  
.scroll-section p {
    font-size: 1.1em; /* Slight increase in font size */
    line-height: 1.8;
    color: #01A09E;
}
/* .scroll-section li {
    font-size: 1.3em; 
    line-height: 1.8;
    color: #01A09E;
} */
.scroll-section.title {
    font-size: 1.3em; /* Slight increase in font size */
    line-height: 1.8;
    color: #01A09E;
}
/* Highlighted Section */
.scroll-section.highlight {
    background-color: var(--highlight-bg);
    border: 1px solid var(--primary-color);
    color: #e0f2ff;
}

/* About Section */
.about-section {
    margin-top: 30px;
    padding: 20px;
    border: 1px solid var(--border-color);
    border-radius: 10px;
    background-color: #f4f6f8;
    color: #fff;
}

.about-section h3 {
    font-size: 2em;
    font-weight: bold;
    margin-bottom: 15px;
    color:#fff;
}

.about-section p {
    font-size: 1.3em;
    line-height: 1.8;
    color: white;
}

/* Global Transition for Interactive Elements */
button,
a,
.hospital-table td {
    transition: all 0.1s ease;
}
/* Default styles for large screens (desktop) */
.hospital-header1 {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 1em;
}

.logos-container {
  display: flex; /* Horizontal alignment of logos */
  align-items: center; /* Vertically align logos */
  gap: 20px; /* Spacing between logos */
}

.hospital-logo {
  display: block; /* Ensures proper rendering for images */
}

.nabh-logo {
  height: 160px;
  width: 160px;
  margin-top: -40px;
}

.nabl-logo {
  height: 85px;
  width: 70px;
  margin-top: -40px;
}

.jcl-logo {
  height: 78px;
  width: 95px;
  margin-top: -44px;
  margin-left: 20px;
  margin-right: 15px;
}

.top-choice-button {
  background-color: #CC0C39;
  border-radius: 3px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
  padding: 20px;
  width: 150px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -80px; /* Adjust button position slightly upwards */
}

.top-choice-button span {
  color: white;
  font-size: 16px;
  font-weight: bold;
}

/* Media Queries */

/* For tablets (max-width: 1024px) */
@media (max-width: 1024px) {
  .hospital-header1 {
    flex-direction: row; /* Keep horizontal alignment for tablets */
    justify-content: space-between; /* Space out elements */
    gap: 15px; /* Reduce spacing slightly */
  }

  .logos-container {
    gap: 15px; /* Adjust logo spacing */
  }

  .top-choice-button {
    margin-top: -60px; /* Adjust button alignment */
  }
  .nabh-logo {
    height: 160px;
    width: 160px;
    margin-top: -40px;
  }
  
  .nabl-logo {
    height: 85px;
    width: 70px;
    margin-top: -40px;
  }
  
  .jcl-logo {
    height: 78px;
    width: 95px;
    margin-top: -44px;
    margin-left: 20px;
    margin-right: 15px;
  }
  .hospital-logo2 {
    width: 150px;
    height: 150px;
    object-fit: contain;
    margin-top: -6px;
}
.top-choice-button {
  width: 17%;
  margin-top: -86px;
}
}
/* For medium screens (max-width: 768px) */
@media (max-width: 768px) {
  .hospital-header1 {
    flex-direction: column; /* Stack items vertically for smaller screens */
    align-items: center; /* Center items */
    gap: 10px; /* Reduce spacing */
  }

  

  .nabh-logo {
    height: 160px;
    width: 160px;
    margin-top: -40px;
  }
  
  .nabl-logo {
    height: 85px;
    width: 70px;
    margin-top: -40px;
  }
  
  .jcl-logo {
    height: 78px;
    width: 95px;
    margin-top: -44px;
    margin-left: 20px;
    margin-right: 15px;
  }

 
  .hospital-logo2 {
    width: 150px;
    height: 150px;
    object-fit: contain;
    margin-top: -79px;
}
}
/* For small screens (max-width: 480px) */
@media (max-width: 480px) {
  .hospital-header1 {
    gap: 5px; /* Minimal spacing for very small screens */
  }

  .logos-container {
    gap: 5px; /* Reduce spacing between logos */
  }

  .nabh-logo {
    height: 130px;
    width: 134px;
    margin-top: -40px;
}
  
.nabl-logo {
  height: 65px;
  width: 59px;
  margin-top: -40px;
}
  
.jcl-logo {
  height: 56px;
  width: 69px;
  margin-top: -44px;
  margin-left: 20px;
  margin-right: 15px;
}

.top-choice-button {
  width: 28%;
  margin-top: -59px;
  white-space: nowrap; /* Prevents text wrapping */
  min-width: 150px; /* Optional: ensures the button doesn't shrink too much on very small screens */
  max-width: 300px; /* Optional: limits the maximum width of the button */
}


  .top-choice-button span {
    font-size: 14px; /* Slightly smaller font for smaller screens */
  }
  .hospital-logo2 {
    width: 150px;
    height: 150px;
    object-fit: contain;
    margin-top: -90px;
}
}
/* Responsive Design */
/* General Mobile/Tablet Adjustments */
@media (max-width: 1024px) {
    .hospital-page {
        flex-direction: column;
        gap: 15px; /* Reduce spacing for compact screens */
    }

    .hospital-header {
        align-items: center;
        text-align: center;
        flex-wrap: wrap; /* Wrap content neatly for smaller screens */
    }

    .hospital-name {
        font-size: 1.8em; /* Adjust font size for better fit */
    }

    .hospital-city,
    .hospital-rating {
        font-size: 1.2em;
    }

   

    .recommendation-logo {
        font-size: 0.8em;
        padding: 0.3em 0.8em; /* Adjust padding for compact appearance */
    }

    .hospital-image {
        width: 60%; /* Adjust image width to fit within 1024px screens */
        height: auto;
        margin-bottom: 20px; /* Increase spacing below the image */
        display: block;
        margin-left: auto;
        margin-right: auto; /* Center image horizontally */
    }

    .hospital-heading {
        font-size: 1.6em; /* Adjust heading size */
    }

    .hospital-details {
        font-size: 1.1em;
    }

    .hospital-table {
        font-size: 1em; /* Smaller font for table */
    }

    .hospital-table td {
        padding: 10px; /* Adjust padding for better fit */
        font-size: 0.9em;
    }

    /* Ensure the box comes below the image */
    .hospital-right {
        clear: both; /* Ensure box is below image */
        display: block; /* Ensure it's a block element */
        margin-top: 200px; /* Provide margin top for spacing */
    }
}

  /* Tablet Adjustments */
  @media (max-width: 850px) {
    .hospital-header {
        flex-direction: column;
        gap: 10px; /* Space out items for better visibility */
    }

    .hospital-name {
        font-size: 1.6em;
    }

    .hospital-city {
        font-size: 1em;
    }

  

    .hospital-details {
        font-size: 1em;
    }

    .scroll-section {
        padding: 15px; /* Reduce padding for compactness */
    }

    .scroll-section h3 {
        font-size: 1.4em;
    }

    .scroll-section p {
        font-size: 1em;
    }

    .hospital-image {
        width: 80%; /* Reduce the size of the image to 80% */
        height: auto;
        margin-top: 10px; /* Compact spacing at the top */
        margin-bottom: 20px; /* Increase bottom margin for more spacing */
        border-radius: 15px; /* Subtle rounding */
        display: block; /* Ensure it behaves as a block element */
        margin-left: auto; /* Center horizontally */
        margin-right: auto; /* Center horizontally */
    }

    /* Make sure the box is below the image */
    .hospital-right {
        clear: both; /* Ensure that the box comes below the image */
        margin-top: 150px; /* Add space between the image and the box */
    }
    .hospital-header {
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin-top: 50px;
      }
    
      .hospital-header img.hospital-logo {
        margin-bottom: 5px;
      }
    
      .hospital-name {
        font-size: 20px;
        margin-top: 10px;
      }
    
      .hospital-city {
        font-size: 17px;
      }
    
      .hospital-rating {
        font-size: 14px;
        margin-top: 5px;
      }
    
      .hospital-image {
        max-width: 90%;
        margin-bottom: 50px;
      }
     
     

}

  
  /* Mobile Adjustments */
  @media (max-width: 480px) {
    .outer-container {
        margin: 5px; /* Reduce margins for tighter spacing */
        padding: 8px; /* Compact padding */
    }

    .inner-container {
        padding: 10px; /* Reduce padding to fit smaller screens */
    }

    .hospital-page {
        flex-direction: column; /* Stack elements vertically */
        gap: 10px; /* Reduce gaps between sections */
    }

    .hospital-left,
    .hospital-right {
        flex: 1;
        width: 100%; /* Take full width */
        min-width: unset; /* Remove fixed minimum width */
    }

    .hospital-header {
        align-items: center; /* Center-align content */
        text-align: center;
    }

    .hospital-name {
        font-size: 1.5em; /* Further reduce font size */
    }

    .hospital-city,
    .hospital-rating {
        font-size: 1em; /* Adjust for readability */
    }

    

    .hospital-image {
        width: 100%; /* Scale image to fit */
        height: auto;
        margin-top: 10px; /* Compact spacing */
        border-radius: 5px; /* Subtle rounding */
    }

    .hospital-details {
        font-size: 0.9em; /* Smaller font size */
        line-height: 1.3; /* Compact line spacing */
    }

    .hospital-heading {
        font-size: 1.2em; /* Smaller heading size */
        margin-bottom: 8px;
    }

    .recommendation-logo {
        font-size: 0.8em; /* Adjust logo text size */
        padding: 0.4em 0.8em; /* Compact padding */
    }

    .hospital-table-section {
        width: 100%; /* Ensure it takes full width of the container */
        padding: 0 10px; /* Small padding for responsiveness */
    }

    .hospital-table {
        width: 100%; /* Ensure the table takes full width of the container */
        table-layout: fixed; /* Ensures even distribution of space */
        border-collapse: collapse; /* Remove extra spaces between table cells */
    }

    .hospital-table td {
        text-align: center; /* Center text in the table cells */
        padding: 10px; /* Compact padding for better fit */
        font-size: 0.9em; /* Slightly smaller text size */
        border: 1px solid #ddd; /* Optional border styling */
    }

    .hospital-table tr {
        display: grid; /* Use grid for the rows */
        grid-template-columns: repeat(2, 1fr); /* Two columns per row */
        gap: 10px; /* Space between cells */
    }

    .hospital-table td {
        box-sizing: border-box; /* Ensure padding is included in width */
        border-radius: 8px; /* Optional rounding of table cells */
        background-color: #f9f9f9; /* Light background for cells */
    }

    /* Optional: Style for active state */
    .hospital-table td.active {
        background-color: #f0f0f0; /* Highlight active section */
        font-weight: bold;
    }

    /* Optional: Add some responsive fixes */
    .scroll-section h3 {
        font-size: 1.2em; /* Compact scroll section */
    }

    .scroll-section p {
        font-size: 0.9em;
    }

    .recommendation-logo {
        font-size: 0.7em;
        padding: 0.2em 0.6em;
    }
    .hospital-right {
        clear: both; /* Ensure that the box comes below the image */
        margin-top: 10px; /* Add space between the image and the box */
    }
    .hospital-header{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .handlrs{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .hospital-page {
        flex-direction: column;
        gap: 10px;
        align-items: center;
      }
    
      .hospital-header {
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin-top: 50px;
      }
    
      .hospital-header img.hospital-logo {
        margin-bottom: 5px;
      }
    
      .hospital-name {
        font-size: 16px;
        margin-top: 10px;
      }
    
      .hospital-city {
        font-size: 14px;
      }
    
      .hospital-rating {
        font-size: 14px;
        margin-top: 5px;
      }
    
      .hospital-image {
        max-width: 90%;
      }
     
    
} 
/* Responsive Design */
/* General Mobile/Tablet Adjustments */
@media (max-width: 1024px) {
    .hospital-page {
        flex-direction: column;
        gap: 15px; /* Reduce spacing for compact screens */
    }

    .hospital-header {
        align-items: center;
        text-align: center;
        flex-wrap: wrap; /* Wrap content neatly for smaller screens */
    }

    .hospital-name {
        font-size: 1.8em; /* Adjust font size for better fit */
    }

    .hospital-city,
    .hospital-rating {
        font-size: 1.2em;
    }

   

    .recommendation-logo {
        font-size: 0.8em;
        padding: 0.3em 0.8em; /* Adjust padding for compact appearance */
    }

    .hospital-image {
        width: 60%; /* Adjust image width to fit within 1024px screens */
        height: auto;
        margin-bottom: 20px; /* Increase spacing below the image */
        display: block;
        margin-left: auto;
        margin-right: auto; /* Center image horizontally */
    }

    .hospital-heading {
        font-size: 1.6em; /* Adjust heading size */
    }

    .hospital-details {
        font-size: 1.1em;
    }

    .hospital-table {
        font-size: 1em; /* Smaller font for table */
    }

    .hospital-table td {
        padding: 10px; /* Adjust padding for better fit */
        font-size: 0.9em;
    }

    /* Ensure the box comes below the image */
    .hospital-right {
        clear: both; /* Ensure box is below image */
        display: block; /* Ensure it's a block element */
        margin-top: 200px; /* Provide margin top for spacing */
    }
}

  /* Tablet Adjustments */
  @media (max-width: 850px) {
    .hospital-header {
        flex-direction: column;
        gap: 10px; /* Space out items for better visibility */
    }

    .hospital-name {
        font-size: 1.6em;
    }

    .hospital-city {
        font-size: 1em;
    }

    
    .hospital-details {
        font-size: 1em;
    }

    .scroll-section {
        padding: 15px; /* Reduce padding for compactness */
    }

    .scroll-section h3 {
        font-size: 1.4em;
    }

    .scroll-section p {
        font-size: 1em;
    }

    .hospital-image {
        width: 80%; /* Reduce the size of the image to 80% */
        height: auto;
        margin-top: 10px; /* Compact spacing at the top */
        margin-bottom: 20px; /* Increase bottom margin for more spacing */
        border-radius: 15px; /* Subtle rounding */
        display: block; /* Ensure it behaves as a block element */
        margin-left: auto; /* Center horizontally */
        margin-right: auto; /* Center horizontally */
    }

    /* Make sure the box is below the image */
    .hospital-right {
        clear: both; /* Ensure that the box comes below the image */
        margin-top: 150px; /* Add space between the image and the box */
    }
    .hospital-header {
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin-top: 50px;
      }
    
      .hospital-header img.hospital-logo {
        margin-bottom: 5px;
      }
    
      .hospital-name {
        font-size: 20px;
        margin-top: 10px;
      }
    
      .hospital-city {
        font-size: 17px;
      }
    
      .hospital-rating {
        font-size: 14px;
        margin-top: 5px;
      }
    
      .hospital-image {
        max-width: 90%;
        margin-bottom: 50px;
      }
    

}

  
  /* Mobile Adjustments */
  @media (max-width: 480px) {
    .outer-container {
        margin: 5px; /* Reduce margins for tighter spacing */
        padding: 8px; /* Compact padding */
    }

    .inner-container {
        padding: 10px; /* Reduce padding to fit smaller screens */
    }

    .hospital-page {
        flex-direction: column; /* Stack elements vertically */
        gap: 10px; /* Reduce gaps between sections */
    }

    .hospital-left,
    .hospital-right {
        flex: 1;
        width: 100%; /* Take full width */
        min-width: unset; /* Remove fixed minimum width */
    }

    .hospital-header {
        align-items: center; /* Center-align content */
        text-align: center;
    }

    .hospital-name {
        font-size: 1.5em; /* Further reduce font size */
    }

    .hospital-city,
    .hospital-rating {
        font-size: 1em; /* Adjust for readability */
    }

   
    .hospital-image {
        width: 100%; /* Scale image to fit */
        height: auto;
        margin-top: 10px; /* Compact spacing */
        border-radius: 5px; /* Subtle rounding */
    }

    .hospital-details {
        font-size: 0.9em; /* Smaller font size */
        line-height: 1.3; /* Compact line spacing */
    }

    .hospital-heading {
        font-size: 1.2em; /* Smaller heading size */
        margin-bottom: 8px;
    }

    .recommendation-logo {
        font-size: 0.8em; /* Adjust logo text size */
        padding: 0.4em 0.8em; /* Compact padding */
    }

    .hospital-table-section {
        width: 100%; /* Ensure it takes full width of the container */
        padding: 0 10px; /* Small padding for responsiveness */
    }

    .hospital-table {
        width: 100%; /* Ensure the table takes full width of the container */
        table-layout: fixed; /* Ensures even distribution of space */
        border-collapse: collapse; /* Remove extra spaces between table cells */
    }

    .hospital-table td {
        text-align: center; /* Center text in the table cells */
        padding: 10px; /* Compact padding for better fit */
        font-size: 0.9em; /* Slightly smaller text size */
        border: 1px solid #ddd; /* Optional border styling */
    }

    .hospital-table tr {
        display: grid; /* Use grid for the rows */
        grid-template-columns: repeat(2, 1fr); /* Two columns per row */
        gap: 10px; /* Space between cells */
    }

    .hospital-table td {
        box-sizing: border-box; /* Ensure padding is included in width */
        border-radius: 8px; /* Optional rounding of table cells */
        background-color: #f9f9f9; /* Light background for cells */
    }

    /* Optional: Style for active state */
    .hospital-table td.active {
        background-color: #f0f0f0; /* Highlight active section */
        font-weight: bold;
    }

    /* Optional: Add some responsive fixes */
    .scroll-section h3 {
        font-size: 1.2em; /* Compact scroll section */
    }

    .scroll-section p {
        font-size: 0.9em;
    }

    .recommendation-logo {
        font-size: 0.7em;
        padding: 0.2em 0.6em;
    }
    .hospital-right {
        clear: both; /* Ensure that the box comes below the image */
        margin-top: 10px; /* Add space between the image and the box */
    }
    .hospital-header{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .handlrs{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .hospital-page {
        flex-direction: column;
        gap: 10px;
        align-items: center;
      }
    
      .hospital-header {
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin-top: 50px;
      }
    
      .hospital-header img.hospital-logo {
        margin-bottom: 5px;
      }
    
      .hospital-name {
        font-size: 17px;
        margin-top: -14px;
    }
    
      .hospital-city {
        font-size: 14px;
      }
    
      .hospital-rating {
        font-size: 14px;
        margin-top: 5px;
      }
    
      .hospital-image {
        max-width: 90%;
      }
     
}
@media (max-width: 1200px) {
    .horizontal-list {
      grid-template-columns: repeat(4, 1fr); /* 4 items in a row on larger tablets */
    }
  }
  
  @media (max-width: 768px) {
    .horizontal-list {
      grid-template-columns: repeat(3, 1fr); /* 3 items in a row on tablets */
    }
  }
  
  @media (max-width: 480px) {
    .horizontal-list {
      grid-template-columns: repeat(2, 1fr); /* 2 items in a row on mobile */
    }
  
    .horizontal-list li {
      height: auto; /* Let the height adjust on smaller screens */
      font-size: 0.9rem; /* Slightly reduce font size for small screens */
    }
  }
  @media (max-width: 768px) {
    .thumbnail {
      width: 70px; /* Reduced size on tablets */
      height: 70px;
      margin-top: -10px;
    }
  }
  
  @media (max-width: 480px) {
    .thumbnail {
      width: 50px; /* Reduced size for mobile screens */
      height: 50px;
    }
  }