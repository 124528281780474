@import url('https://fonts.googleapis.com/css2?family=Sen&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&family=Sen&display=swap');

:root{
    --sen : 'Sen', sans-serif;
    --roboto:'Roboto', sans-serif;
    --white:#ffffff;
    --black:#000000;
    --violet:#7C83FD;
    --blue:#96BAFF;
    --green:#93FFD8;
    --gGreen:#CFFFDC;
    --background:rgba(239, 251, 252, 0.5);
    --ms-primary-color:#005c74;
    --ms-second-color:#01cab8;
    --ms-third-color:#858585;
    --ms-dark-color:#212529;
    --ms-second-font:'Poppins';
}

*{
    margin: 0px ;
    padding: 0px;
    /* border: 1px solid red; */
}

.flex__box{
    display: flex;
    justify-content: center;
    align-items: center;
}

a {
    text-decoration: none !important;
    transition: 0.3s all linear;
}
a:hover {
    color: var(--ms-second-color);
}
body {
    font-family: var(--ms-second-font);
    font-size: 17px;
    line-height: 1.55em;
}
h1, h2, h3, h4, h5, h6 {
    line-height: 1.35em;
    font-family: 'Montserrat', sans-serif;
}
h1 {
    font-size: 56px;
    color: var(--ms-primary-color);
}
h2 {
    font-size: 42px ;
}
h3 {
    font-size: 34px;
}
h4 {
    font-size: 26px;
}
h5 {
    font-size: 22px;
}
h6 {
    font-size: 19px;
}
.ms-font-poppins {
    font-family: var(--ms-second-font);
}
.ms-primary-color {
    color: var(--ms-primary-color);
}
.ms-second-color {
    color: var(--ms-second-color);
}
.ms-third-color {
    color: var(--ms-third-color);
}
.ms-dark-color {
    color: var(--ms-dark-color);
}
.ms-font-weight-600 {
    font-weight: 600;
}
.ms-primary-btn, .ms-secondary-btn {
    position: relative;
    display: inline-block;
    font-size: 17px;
    font-weight: 600;
    padding: 16px 32px;
    border-radius: 30px;
    z-index: 1;
    transition: all 500ms ease;
}
.ms-primary-btn:before, .ms-secondary-btn:before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    border-radius: 30px;
    left: 0px;
    top: 0px;
    z-index: -1;
    transform: scale(0,0);
    transition: all 500ms ease;
}
.ms-primary-btn {
    color: var(--ms-primary-color);
    background: #fff;
    border:2px solid #fff;
}
.ms-primary-btn:before {
    background: var(--ms-primary-color);
}
.ms-primary-btn:hover {
    background: var(--ms-primary-color);
    color: #fff;
    border:2px solid var(--ms-primary-color);
}
.ms-primary-btn:hover:before,
.ms-secondary-btn:hover:before {
    transform: scale(1,1);
}
.ms-secondary-btn {
    background: var(--ms-primary-color);
    border:2px solid var(--ms-primary-color);
    color: #fff !important;
}
.ms-secondary-btn:before {
    background: var(--ms-second-color);
}
.ms-secondary-btn:hover {
    background: var(--ms-second-color);
    border: 2px solid var(--ms-second-color);
    color:#fff;
}
.ms-border-rounded {
    border-radius: 30px;
}

@media (min-width:310px) {
    .ms-primary-btn, .ms-secondary-btn  {   
        padding: 6px 10px ;
    }
}

@media (max-width:767px) {
    /*==================== 01. General CSS ====================*/
    h1 {
        font-size:50px ;
    }
    h2 {
        font-size: 38px ;
    }
    h3 {
        font-size: 31px;
    }
    h4 {
        font-size: 23px;
    }
    h5 {
        font-size: 18px;
    }
}

@media (max-width:550px) {
    /*==================== 01. General CSS ====================*/
    h1 {
        font-size:42px;
    }
    h2 {
        font-size: 34px;
    }
    h3 {
        font-size: 29px;
    }
}
.patient{
    display: flex;
    flex-direction: column;
    margin-top: 157px;

}
.whatsapp-block{
    height: 0;
    width: 49px;
    position: fixed;
    right: 24px;
    top: calc(50% + 210px);
    z-index: 1000;
    transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
}
.whatsapp-block a{
    display: block;
    border-radius: 22px 22px 0 0;
    background: #005c74;
}
.whatsapp-block a img{
transform: rotate(-90deg);
-webkit-transform: rotate(90deg);
margin: 5px;
width: 40px;
border-radius: 50%;
}

.itemss .customImg img {
    width: 375px!important;
    height: 84px !important;
    max-width: 206px !important;
    max-height: 115px !important;
}
.items .customImg img {
  width: 350px !important;
  height: 220px !important;
  max-width: 280px !important;
  max-height: 120px !important;
  margin-right: 20px !important; /* Add some space between images */
}
.mobile-dropdown-list {
    list-style: none;
    padding-left: 20px;
    margin-top: 10px;
  }
  
  .mobile-dropdown-item {
    margin-bottom: 10px;
  }
  
  .mobile-dropdown-item a {
    text-decoration: none;
    color: #333;
    font-size: 14px;
  }
  .image-container {
    position: relative;
    transition: transform 0.3s ease-out;
  }
  
  .hover-forward:hover {
    transform: scale(1.05) translateZ(20px);
  }
  
  .image-container img {
    display: block;
    max-width: 100%;
    height: auto;
  }
  .nav-link-white {
    color: white;
  }
  
  .nav-link-default {
    color: black;
  }
  
  .nav-link-white.active {
    color: white;
  }
  
  .nav-link-default.active {
    color: #018c92;
  }
  .download-button{
    display: inline-block;
    border: 1px solid #fff;
    padding: 5px 10px;
    margin-bottom: 20rem;
    border-radius: 12px,
  }
  .user-select-none {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  @media (max-width: 768px) {
    .items .customImg img {
      width: 200px;
      height: 150px;
      max-width: 100%;
      max-height: 100%;
      margin-right: 15px; /* Adjust the space between images for mobile */
    }
  
    /* Adjust the OwlCarousel settings for mobile */
    .owl-carousel .owl-item {
      padding: 0 10px; /* Add padding to owl-item for better spacing */
    }
  }
  @media (max-width: 480px) {
    .itemss .customImg img {
      width: 150px !important;
      height: 50px !important;
      max-width: 140px !important;
      max-height: 80px !important;
    }
  
    .items .customImg img {
      width: 140px !important;
      height: 80px !important;
      max-width: 120px !important;
      max-height: 70px !important;
      margin-right: 5px !important; /* Further reduce margin for very small screens */
    }
  }