.info-section {
  background-color: #f0f0f0; /* Grey background */
  padding: 40px 0; /* Padding for top and bottom */
}

.info-container {
  background-color: white; /* White rectangle */
  width: 80%; /* Cover 80% of the page width */
  margin: 0 auto; /* Centering the container */
  padding: 20px; /* Padding inside the container */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  text-align: center; /* Centering text */
}

.info-container h2 {
  font-size: 2rem; /* Heading size */
  margin-bottom: 10px; /* Space below heading */
    color: #005C74;

}

.info-container p {
  font-size: 1.2rem; /* Description size */
  margin-bottom: 20px; /* Space below description */
}

.box-container {
  display: flex; /* Flexbox for horizontal alignment */
  justify-content: space-between; /* Space between boxes */
  flex-wrap: wrap; /* Allow boxes to wrap on smaller screens */
}

.box {
  flex: 1 1 calc(16% - 20px); /* Each box takes 16% of the width minus margins */
  margin: 10px; /* Space between boxes */
  padding: 20px; /* Padding inside each box */
  border: 1px solid #ddd; /* Border around the box */
  border-radius: 8px; /* Rounded corners */
  text-align: center; /* Centering text */
  background-color: #fff; /* White background for boxes */
  transition: transform 0.2s; /* Animation on hover */
}

.box:hover {
  transform: scale(1.05); /* Slightly enlarge box on hover */
}

.box h4 {
  margin-top: 10px; /* Space above heading */
  font-size: 1rem; /* Box heading size */
}
/* Base styling (for larger screens) */
.circle-section {
  background-color: #f0f0f0; /* Grey background */
  padding: 40px 0; /* Padding for top and bottom */
}

.circle-container {
  background-color: white; /* White rectangle */
  width: 80%; /* Cover 80% of the page width */
  margin: 0 auto; /* Centering the container */
  padding: 20px; /* Padding inside the container */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  position: relative; /* Positioning for absolute elements */
  text-align: center; /* Centering text */
}

.outer-circles {
  display: flex; /* Flexbox for horizontal alignment */
  justify-content: center; /* Center circles */
  align-items: center; /* Center vertically */
  position: relative; /* Positioning context for lines */
  flex-wrap: wrap; /* Allow circles to wrap on small screens */
}

.headrec {
  margin-bottom: 50px;
  color: #005C74;
}

.circles-container {
display: flex;
justify-content: center; /* Center all circles horizontally */
align-items: center; /* Align all circles vertically */
}

/* Circle styles */
.circle {
background: linear-gradient(90deg, #01A09E, #005C74, #018C92);
border-radius: 50%; /* Make it a circle */
width: 132px; /* Width of each circle */
height: 113px; /* Equal width and height for perfect circle */
display: flex; /* Flexbox for centering text */
align-items: center; /* Centering vertically */
justify-content: center; /* Centering horizontally */
color: white; /* Text color */
margin: 10px; /* Space around each circle */
position: relative; /* For line positioning */
transition: transform 0.3s ease, background-color 0.3s ease; /* Smooth transition for hover effect */
box-shadow: 0px 1px 4px rgba(71, 68, 68, 0.4);
font-size: 15px;
font-weight: 700;
}

/* Hover effect */
.circle:hover {
transform: scale(1.1); /* Slightly enlarge on hover */
background-color: #141414; /* Change color on hover */
}

/* Connecting lines between circles */
.circle::after {
content: ""; /* Empty content for lines */
position: absolute; /* Position absolutely within the circle */
width: 30px; /* Width of the line */
height: 2px; /* Height of the line */
background: linear-gradient(90deg, #01A09E, #005C74, #018C92); /* Color of the line */
top: 50%; /* Center vertically */
right: -15px; /* Adjust position of the line */
transform: translateY(-50%); /* Center the line */
}

/* Hide the last circle's line */
.circle:last-child::after {
display: none; /* No line after the last circle */
}

/* Tablet view */
@media (max-width: 768px) {
  .circle-container {
      width: 90%; /* Increase width to fit more content */
  }
  .circle {
      width: 120px; /* Reduce circle size */
      height: 120px;
  }
  .circle::after {
      width: 20px; /* Shorten line between circles */
  }
}

/* Mobile view */
@media (max-width: 480px) {
  .circle-container {
      width: 95%; /* Almost full width */
  }
  .circle {
      width: 100px; /* Smaller circles for mobile */
      height: 100px;
      font-size: 12px; /* Adjust text size for small screens */
  }
  .circle::after {
      width: 15px; /* Shorten line between circles */
  }
}

@media (max-width: 480px) { /* Mobile screens */
  .info-container {
    padding: 10px; /* Further reduce padding */
  }

  .info-container h2 {
    font-size: 1.6rem; /* Further reduce heading size */
  }

  .info-container p {
    font-size: 0.9rem; /* Further reduce description size */
  }

  .box {
    flex: 1 1 100%; /* Each box takes full width on mobile */
  }
}
@media (max-width: 768px) {
  .circle-container {
      width: 90%; /* Increase width to fit more content */
  }
  .circle {
      width: 120px; /* Reduce circle size */
      height: 120px;
  }
  .circle::after {
      width: 20px; /* Shorten line between circles */
  }
}

/* Mobile view */
@media (max-width: 480px) {
  .circle-container {
      width: 95%; /* Almost full width */
  }
  .circle {
      width: 100px; /* Smaller circles for mobile */
      height: 100px;
      font-size: 12px; /* Adjust text size for small screens */
  }
  .circle::after {
      width: 15px; /* Shorten line between circles */
  }
}
@media (max-width: 480px) {
.fw-normal {
 margin-left: 30%;
}
}
@keyframes fadeInSlideUp {
0% {
  opacity: 0;
  transform: translateY(50px);
}
100% {
  opacity: 1;
  transform: translateY(0);
}
}

/* Optional: Add a bounce effect */
@keyframes bounceIn {
0% {
  opacity: 0;
  transform: scale(0.3);
}
50% {
  opacity: 0.9;
  transform: scale(1.1);
}
80% {
  opacity: 1;
  transform: scale(0.9);
}
100% {
  opacity: 1;
  transform: scale(1);
}
}

/* Optional: Add a fade-in effect */
@keyframes fadeIn {
0% {
  opacity: 0;
}
100% {
  opacity: 1;
}
}

.ms-cureways-section2{
padding: 80px 0;
background:  #018C92;
margin-top: 10%;
}
.ms-cureways-section .ms-icon-box {
padding:50px 30px;
border-radius: 20px;
box-shadow: 0 10px 30px #eaf2f1;
transition: all 500ms ease;
text-align: center;
min-width: 300px;
min-height: 280px;
background:url('../Images/icon-back-box.png')   rgb(148 227 231);
background-size:cover;
background-repeat: no-repeat;
background-position:-15%;
}
.ms-apart-section .ms-icon-box img {
width: 80px;
}
.ms-apart-section .ms-icon-box h5 {
font-size: 18px;
line-height: 1.5em;
}
.ms-cureways-section .ms-icon-box h4{
color: #006076;
}
.ms-cureways-section .ms-icon-box img{
width: 80px;
}
/* #006076 */

/* .ms-apart-section .ms-icon-box {
min-height: 310px;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
} */

.ms-content-section {
background:  rgba(255,255,255,0.6);
background-blend-mode: overlay;
padding: 0px 0;
overflow: hidden;

}

.content__logo>img{
width: 55px !important;
margin: 30px 0px;
}


.margin__first{
margin-top: 30px !important;
}

.margin__second{
margin: 60px 0px !important;
}

.fade__img{
height: 100%;
background-blend-mode:multiply;
}

.ms-content-section .ms-check-icon {
color: var(--ms-second-color);
position: relative;
top: 4px;
font-size: 20px;
}
.ms-content-section h6 {
color: var(--ms-third-color);
line-height: 1.65em;
font-size: 18px;
font-family: var(--ms-second-font);
}


@media (max-width: 992px) {
.scrollable-content,
.fixed-image-container {
  height: auto;
}

.content-section {
  min-height: auto;
  margin-bottom: 2rem;
}

.fixed-image-container {
  position: static;
  margin-bottom: 2rem;
}

.fixed-image {
  position: static;
  opacity: 1;
  margin-bottom: 2rem;
}
}




@media screen and (min-width: 780px) and (max-width: 1200px){



.content__logo>img{
    width: 60px !important;
    margin: 30px 0px;
}

}



@keyframes fade-in-up {
0% { opacity: 0; }
100% { transform: translateY(0); opacity: 1; }
}

.video {
position: fixed;
bottom: 50px; /* Adjust as needed */
right: 5%; /* Move to the center horizontally */
transform: translateX(5%); /* Center the video */
z-index: 9999; /* Ensure it's above other content */

}