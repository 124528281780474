/* Container styles */
.outer-container {
  background-color: rgb(229, 227, 227);
  padding-left: 2%;
  padding-right: 2%;
  padding-top: 1%;
  padding-bottom: 1%;
 
}

.inner-container {
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
}
.treatments-container {
    display: flex;
    gap: 20px;
    padding: 20px;
  }
  
  /* Left section styling */
  .left-section {
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .rectangle {
    background: linear-gradient(180deg, #02b6bc 0%, #eafafa 100%);
    padding: 20px;
    border-radius: 8px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}



  .cost-gradient-line {
    width: 80%;
    height: 10px;
    background: linear-gradient(90deg, #e1ffff , #01A09E    , #005C74); /* Lighter to darker red */
    position: relative;
    margin: 20px 0;
    border-radius: 5px;
  }
  
  .slider {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 15px;
    background: transparent;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: all;
  }
  
  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    background-color: #fff;
    border: 2px solid  #013321;
    border-radius: 50%;
    cursor: pointer;
  }
  
  .slider::-moz-range-thumb {
    width: 20px;
    height: 20px;
    background-color: #fff;
    border: 2px solid #013321;
    border-radius: 50%;
    cursor: pointer;
  }
  
  .price-info {
    text-align: center;
    font-weight: bold;
    font-size: 1rem;
    margin-top: 10px;
  }
  
  .cost-labels {
    display: flex;
    justify-content: space-between;
    width: 100%; /* Ensure labels align with the line width */
    color: black; /* Set text color to black */
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .min-label,
  .avg-label,
  .max-label {
    text-align: center;
    width: 33%;
    margin-bottom: -24px; /* Align labels across the gradient line */
  }
/* doctorsSection.css */

.doctors-section {
  padding: 2rem;
  display: flex;
  justify-content: center;
  background-color: #ffffff;
}

.container-rectangle {
  border-radius: 12px;
  padding: 2rem;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  max-width: 100%;
  width: 100%;
  text-align: center;
  background: linear-gradient(180deg, #02b6bc 0%, #eafafa 100%);
}

.section-heading {
  font-size: 1.8rem;
  color: #333;
  margin-bottom: 2rem;
  font-weight: bold;
}

.doctor-cards {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;
}

.doctor-card {
  display: flex;
  background-color: white;
  border-radius: 10px;
  padding: 1rem;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
  align-items: center;
  transition: transform 0.3s;
}

.doctor-card:hover {
  transform: scale(1.02);
}

.doctor-image img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-right: 1rem;
}

.doctor-info {
  text-align: left;
}

.doctor-name {
  font-size: 1.1rem;
  color: #333;
  font-weight: bold;
}

.doctor-rating {
  font-size: 0.9rem;
  color: #f39c12;
}

.doctor-position {
  font-size: 0.9rem;
  color: #777;
  margin: 0.2rem 0;
}

.doctor-location {
  font-size: 0.85rem;
  color: #999;
  margin-bottom: 0.5rem;
}

.doctor-exp {
  background-color: #e0f7fa;
  color: #00796b;
  font-size: 0.8rem;
  padding: 0.3rem 0.6rem;
  border-radius: 12px;
  display: inline-block;
  margin-bottom: 1rem;
}

.contact-buttons {
  display: flex;
  gap: 0.5rem;
}

.contact-btn, .whatsapp-btn {
  font-size: 0.9rem;
  padding: 0.5rem 0.8rem;
  border-radius: 6px;
  border: none;
  cursor: pointer;
  transition: box-shadow 0.3s;
}

.contact-btn {
  background-color:rgb(255, 203, 37);
  color: white;
}

.contact-btn:hover {
  box-shadow: 0 3px 10px rgba(0, 123, 255, 0.3);
}

.whatsapp-btn {
  background-color: #17d45d;
  color: white;
}

.whatsapp-btn:hover {
  box-shadow: 0 3px 10px rgba(37, 211, 102, 0.3);
}

.rectangle-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 10px;
}

.small-rectangle {
    width: 30%;
    height: 50px;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    font-size: 12px;
    text-align: center;
}
.centered-rectangle {
    width: 30%;
    height: 50px;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    font-size: 12px;
    text-align: center;
  }
  .cost-buttons {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin-bottom: 20px;
  }
  
  .cost-btn {
    padding: 10px 20px;
    border: none;
    background-color:#84fffd;
    color: black;
    cursor: pointer;
    font-size: 14px;
    border-radius: 4px;
  }
  
  .cost-btn.active {
    background-color: #005C74;
    color: white;
  }
  
  .cost-btn:hover {
    background-color: #005C74;
  }
  
  .price-info-box {
    width: 100%;
    height: 50px;
    background-color: #005C74;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    font-size: 14px;
    text-align: center;
  }
  .price-info-box {
    width: 30%;
    height: 50px;
    background-color: #005C74;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    font-size: 12px;
    text-align: center;
  }
  /* Right section styling */
  .right-section {
    width: 40%;
    padding: 20px;
  }
  
  .heading {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .description {
    font-size: 16px;
    color: #333;
    margin-top: 40px;
  }
  /* Full-width background styling for the new section */
.hip-replacement-section {
    width: 100%;
    padding: 40px 20px;
    background: linear-gradient(180deg, #02b6bc 0%, #eafafa 100%);
  }
  
  .container-full {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .hip-heading {
    font-size: 24px;
    font-weight: bold;
    color: #005C74;
    text-align: left;
    margin-bottom: 16px;
  }
  
  .hip-description {
    font-size: 16px;
    line-height: 1.6;
    color: #333;
    text-align: left;
    margin-bottom: 24px;
  }
  
  .common-conditions-heading {
    font-size: 20px;
    font-weight: bold;
    color: #005C74;
    text-align: left;
    margin-bottom: 16px;
  }
  
  .conditions-list {
    font-size: 16px;
    line-height: 1.6;
    color: #333;
    list-style: none;
    padding-left: 0;
  }
  
  .conditions-list li {
    margin-bottom: 8px;
    display: flex;
    align-items: center;
  }
  /* Responsive Design for Screens Max-Width 820px */
/* Responsive Design for Screens Max-Width 820px */
/* Responsive Design for Screens Max-Width 820px */
/* Responsive Design for screens with width <= 820px */
/* Responsive Design for screens with width <= 820px */
/* Responsive Design for screens <= 820px */
/* Responsive Design for screens <= 820px */
/* Responsive Design for screens <= 820px */
@media (max-width: 820px) {

  /* Adjust outer container padding */
  .outer-container {
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 2%;
    padding-bottom: 2%;
  }

  /* Adjust inner container */
  .inner-container {
    padding: 1.5rem;
  }

  /* Flexbox layout for the treatment container */
  .treatments-container {
    flex-direction: column; /* Stack vertically on smaller screens */
    gap: 15px;
    padding: 15px;
  }

  /* Left section should take full width */
  .left-section {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /* Adjust the rectangle container */
  .rectangle {
    width: 100%;  /* Full width for small screens */
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
  }

  /* Adjust the cost gradient line width */
  .cost-gradient-line {
    width: 100%;  /* Full width for mobile */
  }

  /* Adjust the slider thumb size */
  .slider::-webkit-slider-thumb, .slider::-moz-range-thumb {
    width: 15px;
    height: 15px;
  }

  /* Adjust price-info box */
  .price-info-box {
    width: 100%; /* Full width for price info box */
    margin-bottom: 15px;
  }

  /* Price labels adjusted for smaller screens */
  .cost-labels {
    display: flex;
    flex-direction: row; /* Align labels horizontally */
    justify-content: space-between; /* Space between min, avg, max labels */
    width: 100%;
    gap: 50px; /* Increase the gap between labels */
  }
  
  .min-label, .avg-label, .max-label {
    text-align: center;
    font-size: 14px;  /* Adjust the font size */
    width: 30%; /* Each label takes up 30% width instead of 50% */
    margin-top: 20px; /* Ensure no extra margin is applied */
  }
  
  /* Optionally, you can use individual margins for each label to control the space */
  .min-label {
    margin-right: 10px; /* Add some margin to the right side of the min label */
  }
  
  .avg-label {
    margin-left: 10px;  /* Add some margin to the left side of the avg label */
    margin-right: 10px; /* Add margin on both sides */
  }
  
  .max-label {
    margin-left: 10px; /* Add margin to the left side of the max label */
  }

  /* Doctor section layout changes */
  .doctor-cards {
    grid-template-columns: 1fr;  /* Single column for smaller screens */
    gap: 1.5rem;
  }

  .doctor-card {
    width: 100%; /* Ensure cards take full width */
  }

  /* Doctor image size adjustment */
  .doctor-image img {
    width: 60px;
    height: 60px;
  }

  /* Adjust button sizes for mobile */
  .contact-btn, .whatsapp-btn {
    font-size: 14px;
    padding: 0.5rem;
  }

  /* Adjust the rectangle row for smaller screens */
  .rectangle-row {
    flex-wrap: wrap; /* Allow wrapping to next line */
    gap: 15px; /* Add space between the small boxes */
    width: 100%;
  }

  /* First box takes full width */
  .small-rectangle:first-child {
    width: 100%;  /* First rectangle takes full width */
  }

  /* Small boxes should be in pairs, each takes 48% width */
  .small-rectangle:nth-child(2),
  .small-rectangle:nth-child(3),
  .small-rectangle:nth-child(4),
  .small-rectangle:nth-child(5),
  .small-rectangle:nth-child(6),
  .small-rectangle:nth-child(7) {
    width: 48%;  /* Each rectangle takes 48% width */
    height: 50px;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    font-size: 12px;
    text-align: center;
  }

  /* Centered rectangle will stay below all the others */
  .centered-rectangle {
    width: 80%;  /* Reduce size slightly to fit in screen */
    margin: 20px auto;  /* Centered below the other boxes */
  }

  /* Right section (heading and description) adjustments */
  .right-section {
    width: 100%;
    padding: 20px;
  }

  /* Adjust heading size */
  .heading {
    font-size: 20px;
    margin-bottom: 10px;
  }

  /* Adjust description size */
  .description {
    font-size: 14px;
    margin-top: 20px;
  }

  /* Full-width background styling for the new section */
  .hip-replacement-section {
    width: 100%;
    padding: 20px;
    background: linear-gradient(180deg, #02b6bc 0%, #eafafa 100%);
  }

  /* Container styling for the hip replacement section */
  .container-full {
    width: 100%;
    margin: 0;
    padding: 20px;
  }

  /* Heading and description adjustments in hip-replacement section */
  .hip-heading, .common-conditions-heading {
    font-size: 18px;
    margin-bottom: 16px;
  }

  .hip-description, .conditions-list {
    font-size: 14px;
    margin-bottom: 16px;
  }
}
/* Responsive Design for screens <= 540px */
@media (max-width: 540px) {

  /* Adjust outer container padding */
  .outer-container {
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 2%;
    padding-bottom: 2%;
  }

  /* Adjust inner container */
  .inner-container {
    padding: 1.2rem;  /* Slightly reduced padding */
  }

  /* Flexbox layout for the treatment container */
  .treatments-container {
    flex-direction: column; /* Stack vertically on smaller screens */
    gap: 10px;  /* Reduced gap */
    padding: 10px;
  }

  /* Left section should take full width */
  .left-section {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /* Adjust the rectangle container */
  .rectangle {
    width: 100%;  /* Full width for small screens */
    padding: 12px;  /* Reduced padding */
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;  /* Reduced gap */
  }

  /* Adjust the cost gradient line width */
  .cost-gradient-line {
    width: 100%;  /* Full width for mobile */
  }

  /* Adjust the slider thumb size */
  .slider::-webkit-slider-thumb, .slider::-moz-range-thumb {
    width: 12px;
    height: 12px;  /* Slightly smaller slider thumb */
  }

  /* Adjust price-info box */
  .price-info-box {
    width: 100%; /* Full width for price info box */
    margin-bottom: 12px; /* Reduced margin */
  }

  /* Price labels adjusted for smaller screens */
  .cost-labels {
    flex-direction: row; /* Align labels horizontally */
    justify-content: space-between; /* Space between min, avg, max labels */
    width: 100%;
    gap: 10px; /* Smaller gap between labels */
  }

  .min-label, .avg-label, .max-label {
    width: 32%; /* Each label takes up 32% width */
    text-align: center;
    font-size: 12px;  /* Adjust the font size */
  }

  /* Doctor section layout changes */
  .doctor-cards {
    grid-template-columns: 1fr;  /* Single column for smaller screens */
    gap: 1rem;
  }

  .doctor-card {
    width: 100%; /* Ensure cards take full width */
  }

  /* Doctor image size adjustment */
  .doctor-image img {
    width: 50px;
    height: 50px;  /* Slightly smaller image */
  }

  /* Adjust button sizes for mobile */
  .contact-btn, .whatsapp-btn {
    font-size: 12px;  /* Smaller button text */
    padding: 0.4rem;  /* Slightly smaller padding */
  }

  /* Adjust the rectangle row for smaller screens */
  .rectangle-row {
    flex-wrap: wrap; /* Allow wrapping to next line */
    gap: 12px; /* Add space between the small boxes */
    width: 100%;
  }

  /* First box takes full width */
  .small-rectangle:first-child {
    width: 100%;  /* First rectangle takes full width */
  }

  /* Small boxes should be in pairs, each takes 48% width */
  .small-rectangle:nth-child(2),
  .small-rectangle:nth-child(3),
  .small-rectangle:nth-child(4),
  .small-rectangle:nth-child(5),
  .small-rectangle:nth-child(6),
  .small-rectangle:nth-child(7) {
    width: 48%;  /* Each rectangle takes 48% width */
    height: 45px;  /* Slightly smaller height */
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    font-size: 11px;  /* Smaller font size */
    text-align: center;
  }

  /* Centered rectangle will stay below all the others */
  .centered-rectangle {
    width: 75%;  /* Reduced size slightly to fit in screen */
    margin: 15px auto;  /* Centered below the other boxes */
  }

  /* Right section (heading and description) adjustments */
  .right-section {
    width: 100%;
    padding: 15px;
  }

  /* Adjust heading size */
  .heading {
    font-size: 18px;  /* Smaller heading font */
    margin-bottom: 8px;  /* Reduced margin */
  }

  /* Adjust description size */
  .description {
    font-size: 12px;  /* Smaller font size */
    margin-top: 15px;  /* Reduced margin */
  }

  /* Full-width background styling for the new section */
  .hip-replacement-section {
    width: 100%;
    padding: 15px;
    background: linear-gradient(180deg, #02b6bc 0%, #eafafa 100%);
  }

  /* Container styling for the hip replacement section */
  .container-full {
    width: 100%;
    margin: 0;
    padding: 15px;
  }

  /* Heading and description adjustments in hip-replacement section */
  .hip-heading, .common-conditions-heading {
    font-size: 16px;  /* Smaller heading font */
    margin-bottom: 14px;  /* Reduced margin */
  }

  .hip-description, .conditions-list {
    font-size: 12px;  /* Smaller font size */
    margin-bottom: 12px;  /* Reduced margin */
  }
}
/* Responsive Design for screens <= 430px */
@media (max-width: 430px) {

  /* Adjust outer container padding */
  .outer-container {
    padding-left: 4%;
    padding-right: 4%;
    padding-top: 2%;
    padding-bottom: 2%;
  }

  /* Adjust inner container */
  .inner-container {
    padding: 1rem;  /* Reduced padding */
  }

  /* Flexbox layout for the treatment container */
  .treatments-container {
    flex-direction: column; /* Stack vertically on smaller screens */
    gap: 8px;  /* Reduced gap */
    padding: 8px;
  }

  /* Left section should take full width */
  .left-section {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /* Adjust the rectangle container */
  .rectangle {
    width: 100%;  /* Full width for small screens */
    padding: 10px;  /* Further reduced padding */
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;  /* Further reduced gap */
  }

  /* Adjust the cost gradient line width */
  .cost-gradient-line {
    width: 100%;  /* Full width for mobile */
  }

  /* Adjust the slider thumb size */
  .slider::-webkit-slider-thumb, .slider::-moz-range-thumb {
    width: 10px;
    height: 10px;  /* Smaller slider thumb */
  }

  /* Adjust price-info box */
  .price-info-box {
    width: 100%; /* Full width for price info box */
    margin-bottom: 10px; /* Reduced margin */
  }

  /* Price labels adjusted for smaller screens */
  .cost-labels {
    flex-direction: row; /* Align labels horizontally */
    justify-content: space-between; /* Space between min, avg, max labels */
    width: 100%;
    gap: 8px; /* Smaller gap between labels */
  }

  .min-label, .avg-label, .max-label {
    width: 32%; /* Each label takes up 32% width */
    text-align: center;
    font-size: 11px;  /* Smaller font size */
  }

  /* Doctor section layout changes */
  .doctor-cards {
    grid-template-columns: 1fr;  /* Single column for smaller screens */
    gap: 1rem;
  }

  .doctor-card {
    width: 100%; /* Ensure cards take full width */
  }

  /* Doctor image size adjustment */
  .doctor-image img {
    width: 40px;
    height: 40px;  /* Smaller image */
  }

  /* Adjust button sizes for mobile */
  .contact-btn, .whatsapp-btn {
    font-size: 10px;  /* Smaller button text */
    padding: 0.4rem;  /* Smaller padding */
  }

  /* Adjust the rectangle row for smaller screens */
  .rectangle-row {
    flex-wrap: wrap; /* Allow wrapping to next line */
    gap: 8px; /* Smaller gap between the small boxes */
    width: 100%;
  }

  /* First box takes full width */
  .small-rectangle:first-child {
    width: 100%;  /* First rectangle takes full width */
  }

  /* Small boxes should be in pairs, each takes 48% width */
  .small-rectangle:nth-child(2),
  .small-rectangle:nth-child(3),
  .small-rectangle:nth-child(4),
  .small-rectangle:nth-child(5),
  .small-rectangle:nth-child(6),
  .small-rectangle:nth-child(7) {
    width: 48%;  /* Each rectangle takes 48% width */
    height: 40px;  /* Smaller height */
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    font-size: 10px;  /* Smaller font size */
    text-align: center;
  }

  /* Centered rectangle will stay below all the others */
  .centered-rectangle {
    width: 70%;  /* Reduced size for very small screens */
    margin: 12px auto;  /* Centered below the other boxes */
  }

  /* Right section (heading and description) adjustments */
  .right-section {
    width: 100%;
    padding: 12px; /* Reduced padding */
  }

  /* Adjust heading size */
  .heading {
    font-size: 16px;  /* Smaller heading font */
    margin-bottom: 6px;  /* Reduced margin */
  }

  /* Adjust description size */
  .description {
    font-size: 10px;  /* Smaller font size */
    margin-top: 12px;  /* Reduced margin */
  }

  /* Full-width background styling for the new section */
  .hip-replacement-section {
    width: 100%;
    padding: 12px; /* Reduced padding */
    background: linear-gradient(180deg, #02b6bc 0%, #eafafa 100%);
  }

  /* Container styling for the hip replacement section */
  .container-full {
    width: 100%;
    margin: 0;
    padding: 12px; /* Reduced padding */
  }

  /* Heading and description adjustments in hip-replacement section */
  .hip-heading, .common-conditions-heading {
    font-size: 14px;  /* Smaller heading font */
    margin-bottom: 12px;  /* Reduced margin */
  }

  .hip-description, .conditions-list {
    font-size: 10px;  /* Smaller font size */
    margin-bottom: 10px;  /* Reduced margin */
  }
}