/* Section container */
.treatment-home {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 60px;
    background-color: #edf2f7;
    
}

/* Heading and description */
.heading-description {
    text-align: center;
    margin-bottom: 30px;
    max-width: 800px;
}

.heading-description h2 {
    font-size: 2.5rem;
    color: #090909;
    margin-bottom: 10px;
}

.heading-description p {
    font-size: 1.2rem;
    color: #090909;
    margin: 0 auto;
}

/* Rectangle container for grid layout */
.rectangle1-container {
    display: grid;
    grid-template-columns: repeat(5, 1fr); /* 5 rectangles per row */
    gap: 20px;
    max-width: 1200px;
    width: 100%;
    margin-top: 20px;
}

/* Rectangle styling */
.rectangle1 {
    background: linear-gradient(to bottom, #fff 60%, #01A09E 60%);
    width: 100%;
    height: 300px; /* Increased height for better image fit */
    padding: 20px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center; /* Vertically center the content */
    align-items: center; /* Horizontally center the content */
    position: relative;
    gap: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.rectangle1:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Plus icon styling */
.plus-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    color: #018C92;
    font-size: 1.5em;
    cursor: pointer;
}

/* Image styling inside rectangle */
.rectangle1 img {
    width: 80%; /* Increased width for larger image */
    height: auto;
    max-height: 150px; /* Ensures image does not exceed rectangle height */
    object-fit: cover;
    margin-bottom: 15px; /* Space between image and text */
}

/* Name text styling */
.name {
    font-size: clamp(0.9rem, 1.5vw, 1.2rem);
    /* color: #f9fafa; */
    text-align: center;
    margin-top: 10px;
}

/* Ensuring images are centered */
.rectangle1 img {
    display: block; /* Removes inline block behavior */
    margin-left: auto;
    margin-right: auto; /* Centers image horizontally */
}

/* Responsive adjustments */
@media (max-width: 1024px) {
    .rectangle1-container {
        grid-template-columns: repeat(4, 1fr); /* 4 rectangles per row */
    }
    .heading-description h2 {
        font-size: 2rem;
    }
    .heading-description p {
        font-size: 1.1rem;
    }
    .rectangle1 {
        height: 260px;
    }
}

@media (max-width: 768px) {
    .rectangle1-container {
        grid-template-columns: repeat(3, 1fr); /* 3 rectangles per row */
    }
    .rectangle1 {
        height: 240px;
    }
    .plus-icon {
        font-size: 1.2em;
    }
    .heading-description h2 {
        font-size: 1.8rem;
    }
}

@media (max-width: 480px) {
    .rectangle1-container {
        grid-template-columns: repeat(2, 1fr); /* 2 rectangles per row */
    }
    .rectangle1 {
        height: 220px;
    }
    .plus-icon {
        font-size: 1em;
    }
    .heading-description h2 {
        font-size: 1.5rem;
    }
}